#select-option-component {
  .css-1pahdxg-control{
    box-shadow: none !important;
    background-color: #f6f6f6 !important;
    border-color: #E8EBFF !important;
  }
  .css-yk16xz-control {
    background-color: #f6f6f6 !important;
    border-color: #E8EBFF !important;
   
  }
  .css-1okebmr-indicatorSeparator{
    display: none!important;
  }
  // .css-tlfecz-indicatorContainer {
  //   color: #333333;
  //   &:before{
  //     font-family: "fontello";
  //     content: "\e803";
  //     transform: rotate(-90deg);
  //   }
  // }
  // .css-1gtu0rj-indicatorContainer{
  //   color: #333333;
  //     &:before{
  //       font-family: "fontello";
  //       content: "\e803";
  //       transform: rotate(90deg);
  //     } 
  // }
  // .css-6q0nyr-Svg{
  //   display: none !important;
  // }
}
