.name-of-side {
  margin-top: 25px !important;
}

.w-h-30px{
  width: 30px;
  height: 30px
}

.side-item {
  // margin-left: 44px;
  margin-top: 15px;
}

.list-menu:hover {
  background-color: #e8ebff;
}

.list-menu-active {
  background-color: #e8ebff;
  color: $primary;
  a {
    color: $primary;
  }
}

.list-menu {
  font-size: 16px;
  line-height: 21px;
  a {
    color: #333333;
  }
}



.title-sidebar-menu {
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
}


.display-block {
    display: block;
    padding: .5rem 1rem;
}

.sidebar-extra-data {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    padding: 1em 3em;
    width: 100%;
    text-align: center;
    display: block;
  }
}
.side-item:hover {
  background-color: #313134;
  color: #fff
}

.side-item-active {
  background-color: #313134;
  color: #fff
}