html {
  height: 100%;
}

body {
  background-color: #e5e5e5;
  height: 100%;
}

#header {
  width: 100%;
  // margin-bottom: 1%;
  position: sticky;
  top: 0;
  z-index: 999;
}
#sidebar-left {
  float: left;
  width: 15rem;
  background-color: white;
  height: 100vh;
  position: fixed;
  left: 0;
}
#main {
  padding: 15px;
  width: 100%;
  height: 100%;
  background-color: white;
  padding-left: 16rem;
  // background-color: #e5e5e5;
  // border-radius: 8px;
}

#main-extra {
  padding: 15px;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  padding-left: 16rem;
  // background-color: #e5e5e5;
  // border-radius: 8px;
}


#footer {
  clear: both;
  height: 50px;
  width: 100%;
  text-align: center;
  background-color: lightblue;
}
// #sidebar-left,
// #main,
// #sidebar-right {
//   min-height: 600px;
// }
