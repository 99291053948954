#box-order-history {
  overflow: scroll;
  // height: 50%;
  background-color: $Background;
  padding: 25px;
  border-radius: 5px;
  .box-order {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 8px;
    width: 100%;
    // height: 36rem;
  }
}

.background-modalhistory {
  padding: 15px;
  background-color: #f6f6f6;
  width: 18rem;
  height: 21rem;
  overflow: scroll;
  border: 1px solid #cdd4ff;
}

.background-listhistory {
  background-color: white;
  padding: 10px;
}

@media only screen and (min-width: 992px) {
  div.line-center {
    border-left: 1px solid #e0e0e0;
    height: 90%;
    position: absolute;
    left: 56%;
    margin-left: -3px;
    top: -25px;
    margin-top: 12%;
  }
}
// .line-center {
//     border-left: 1px solid #E0E0E0;
//     height: 88%;
//     position: absolute;
//     left: 50%;
//     margin-left: -3px;
//     top: 0;
//     margin-top: 12%;
// }

.overflow-history {
  overflow: scroll;
}

.tabactive-history {
  color: $primaryColor;
}

.tabinactive-history {
  background-color: #e0e0e0;
  color: #828282;
}

.list-invoice-waitpayment {
  overflow: scroll;
  height: 36rem;
}

.text-area-note {
  width: 23rem;
  height: 98px;
  border-radius: 5px;
  background-color: rgb(246, 246, 246);
  resize: none;
  border: 1px solid #e8ebff;
  outline: none;
  color: #828282
}

.icon-purchase-note {
  width: 10px;
  position: relative;
  top: 35%;
}