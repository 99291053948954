#modal-alert-component {
  padding: 10px;
  .header {
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }
  .body {
    margin-top: 20px;
    text-align: center;
  }
  .footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    .btn-footer {
      width: 14rem;
    }
  }
}
