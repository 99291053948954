#create-order {
  background-color: #fff;
  padding: 1em;
  .card-menu {
    border: 1px solid $E8EBFF;
    .card-body {
      padding: $unit5 $unit10;
      .wrap-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        .select-menu {
          display: flex;
          justify-content: space-between;
          padding-top: $unit5;
        }

      }
      .card-text {
        font-size: 16px;
        color: $primary;
        margin-bottom: 0;
      }
    }
    .detail-menu {
      font-size: 12px;
      color: #004ec2;
      padding: 0px 0px 5px 10px;
    }
  }
  .component-tap {
    height: 88vh;
    overflow: hidden;
  }
  .box-number-tab-in-active {
    width: 45px;
    height: 45px;
    background-color: $E8EBFF;
    text-align: center;
    padding: 0;
  }

  .box-number-tab-active {
    width: 45px;
    height: 45px;
    background-color: #cdd4ff;
    text-align: center;
    padding: 0;
  }
  .nav-tabs {
    border-bottom: 0 !important;
  }
  .box-tab-content {
    // border-left: 20px solid #cdd4ff;
    // border-right: 20px solid #cdd4ff;
    // border-radius: 20px;
    position: relative;
    border: 0;
    height: 78vh;
    .inner {
      // padding-bottom: 80px;
      border-top-right-radius: 5px;
      border: 20px solid #cdd4ff;
      border-right: 13px solid #cdd4ff;
      border-bottom: 5px solid #cdd4ff;
      background-color: #cdd4ff;
      // border-radius: 5px;
      height: 78vh;
      overflow-y: scroll;
      overflow-x: hidden;
      // position: relative;
      .tab-content {
        border: 1px solid #ffffff;
        background-color: #ffffff;
        border-radius: 5px;
        padding: $unit15;
      }
    }
    .inner,
    [data-css-y1c0xs] {
      // padding-right: 10px;
    }
    .inner::-webkit-scrollbar {
      width: $unit5;
    }
    .inner::-webkit-scrollbar,
    .tinner::-webkit-scrollbar-thumb {
      overflow: visible;
      border-radius: 4px;
    }
    .inner::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      z-index: 9999999999999;
    }
    .cover-bar {
      position: absolute;
      background: #cdd4ff;
      height: 74vh;
      top: 15px;
      right: 8px;
      width: $unit10;
      -webkit-transition: all 0.5s;
      opacity: 1;
    }
    .inner {
      &:hover {
        .cover-bar {
          opacity: 0;
          -webkit-transition: all 0.5s;
        }
      }
    }
  }

  .font-number-tab-user {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: $primary;
  }

  .box-select-date-inactive {
    border: 1px solid $E8EBFF;
    border-radius: 5px;
    width: 362px;
    padding: 5px;
  }

  .box-select-date-active {
    border: 1px solid $E8EBFF;
    border-radius: 5px;
    width: 362px;
    padding: 5px;
    background-color: $primary;
  }

  .box-menu-selecct {
    position: relative;
    border: 1px solid $E8EBFF;
    width: 100%;
    border-radius: 5px;
    padding: $unit15;
    display: flex;
    flex-direction: column;
    .detail {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      .menu {
        width: 100%;
      }
      .item-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        min-width: 14rem;
        .name {
          min-width: max-content;
        }
        .products {
          min-width: max-content;
          padding-left: 15px;
        }
      }
    }
  }

  .font-stock-menu {
    color: #e94d4d;
    font-size: 16px;
  }

  .box-summary-order {
    width: 100%;
    border: 1px solid $E8EBFF;
    border-radius: 5px;
    margin-bottom: 76px;

    .box-header {
      background-color: $E8EBFF;
      border-radius: 5px 5px 0px 0px;
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .box-body {
      padding: 5px 30px 5px 30px;
    }
  }

  .input-search {
    background: url(../../assets/Icons/Search.svg) no-repeat scroll 7px 7px;
    padding-left: 40px;
    background-size: 20px;
    border-radius: 5px;
  }

  .box-button-checkout {
    background-color: #cdd4ff;
    padding: 10px 20px 20px 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: 0;
    z-index: 99;
    width: 100%;
    left: 0;
    display: flex;
  }

  .box-caed-empty {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 50px;
      line-height: 65px;
      color: $E8EBFF;
    }
  }

  .icon-delete-product {
    position: absolute;
    top: -12px;
    right: -9px;
  }
  .box-discount {
    background-color: $E8EBFF;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    margin-top: 14px;
    position: relative;
    // .input-code{
    //   position: relative;
    // }
    .icon-status-code {
      position: absolute;
      right: 23px;
      top: 42px;
    }
  }

  .disable-card-product {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%;
  }

  .close-icon-search {
    width: 17px;
    height: 18px;
  }

  .close-icon-search:hover {
    width: 20px;
    height: 20px;
  }

  .search-inside-input {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 50;
  }
}

.bin-icon {
  height: 20px;
  width: 20px;
}

.scroll-date-view {
  background-color: white;
  width: 29rem;
  height: 9rem;
  overflow: auto;
}
