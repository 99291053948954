#pagination-component {
  .pagination {
    .page-item {
      // border-radius: 5px !important;
      margin-left: 5px;
      margin-right: 5px;
      .page-link {
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        color: black;
        font-weight: bold;
        span {
          color: $primary !important;
        }
      }
    }
    .page-item.active .page-link {
      background-color: $primary !important;
      color: white;
    }
  }
}
