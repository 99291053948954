#notification-component {
  .notification {
    border-top: none !important;
  }
  .notifications-tr {
    width: 375px !important;
  }
  .notification-error {
    background-color: #ffcbcb !important;
    .notification-message {
      color: #e94d4d !important;
    }
    .notification-dismiss {
      font-size: 27px !important;
      width: 20px !important;
      height: 20px !important;
      margin-top: 10px;
      background-color: #ffcbcb !important;
      color: #c4c4c4 !important;
    }
  }
}
