#loading-component {
  .wrap-loading {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.8);
    overflow-x: hidden;
    transition: 0.2s;
    text-align: center;
    z-index: 100000;
  }
  .wrap-icon-loading {
    width: 100%;
    height: 100vh;
    padding-top: 32vh;
    transition: 0.2s;
  }
  .wrap-icon-loading i {
    color: #ffffff;
  }
  .loading-box {
    width: 252px;
    height: 80px;
    background: #cdd4ff;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-text {
    margin-left: 10px;
    font-weight: bold;
    font-size: 32px;
    line-height: 42px;
    color: $primary;
  }
  .text-primary {
    color: $primary !important;
  }
}
