#modal-main-compenent {
  .modal-main-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 20px 2px 20px;
    .text-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 26px;
    }
    .icon-close {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
  .modal-main-body::-webkit-scrollbar {
    width: $unit5;
  }
  .modal-main-body::-webkit-scrollbar,
  .modal-main-body::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 4px;
  }
  .modal-main-body::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
  .cover-bar-modal {
    position: absolute;
    background: #ffffff;
    height: 100%;
    top: 0;
    right: 1px;
    width: $unit15;
    -webkit-transition: all 0.5s;
    border-radius: 0.3rem;
    opacity: 1;
  }
  .modal-main-body {
    &:hover {
      .cover-bar-modal {
        opacity: 0;
        -webkit-transition: all 0.5s;
      }
    }
  }
  .modal-main-body {
    padding: 0px 20px 20px 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 85vh;
    width: 100%;
    .box-list-address-user {
      .wrap-list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        .address {
          width: 100%;
          padding-bottom: $unit10;
        }
        .trash {
          width: 22px;
          height: 28px;
          img {
            width: 20px;
            height: 20px;
          }
        }
        .more-details {
          width: 100%;
          padding-bottom: $unit15;
        }
        .footer-check {
          width: 100%;
          padding-bottom: $unit15;
        }
        .check-box {
          display: flex;
          align-items: center;
          label {
            margin-bottom: 0;
            padding-left: $unit10;
          }
        }
      }
    }
    .column-list-modal {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: flex-start;
      padding-left: $unit15;
    }
    /// modal address ///
    .box-list-address-inactive {
      border: 1px solid #e8ebff;
      border-radius: 5px;
      padding: $unit15;
      width: 100%;
      overflow-y: scroll; /* showing scrollbars */
      height: 151px;
      display: flex;
      flex-direction: row;

      .check {
        width: 16px;
        height: 16px;
      }
    }
    .box-list-address-inactive::-webkit-scrollbar {
      width: $unit5;
    }
    .box-list-address-inactive::-webkit-scrollbar,
    .box-list-address-inactive::-webkit-scrollbar-thumb {
      overflow: visible;
      border-radius: 4px;
    }
    .box-list-address-inactive::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      z-index: 99;
    }
    .box-list-address-inactive {
      .cover-bar {
        position: absolute;
        background: #ffffff;
        height: 96%;
        top: 3px;
        right: 1px;
        width: $unit15;
        -webkit-transition: all 0.5s;
        opacity: 1;
        z-index: 9999999;
        border: 1px solid transparent;
      }
      &:hover {
        .cover-bar {
          opacity: 0;
          -webkit-transition: all 0.5s;
        }
      }
    }
    .box-list-address-active::-webkit-scrollbar {
      width: $unit5;
      background: #e8ebff;
    }
    .box-list-address-active::-webkit-scrollbar,
    .box-list-address-active::-webkit-scrollbar-thumb {
      overflow: visible;
      border-radius: 4px;
    }
    .box-list-address-active::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      z-index: 99;
      margin: 3px 0;
    }
    .box-list-address-active {
      & {
        .cover-bar {
          position: absolute;
          background: #e8ebff;
          height: 96%;
          top: 3px;
          right: 1px;
          width: $unit15;
          -webkit-transition: all 0.5s;
          opacity: 1;
          z-index: 9999999;
        }
      }
      &:hover {
        .cover-bar {
          opacity: 0;
          -webkit-transition: all 0.5s;
        }
      }
    }
    .box-list-address-active {
      border: 1px solid #e8ebff;
      border-radius: 5px;
      padding: $unit15;
      background-color: #e8ebff;
      width: 100%;
      overflow-y: scroll; /* showing scrollbars */
      height: 151px;
      display: flex;
      flex-direction: row;
      .check {
        width: 16px;
        height: 16px;
      }
    }
    .text-address-default {
      background-color: #cdd4ff;
      color: $primary;
      width: 65px;
    }
    /// modal address ///

    /// modal summary order ///
    .box-show-summary-order {
      padding: 10px 50px 10px 50px;
      .box-message {
        width: 100%;
        .header {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #e8ebff;
          border-radius: 5px 5px 0px 0px;
          // padding-top: 10px;
          height: 61px;
        }
        .content {
          background-color: #f6f6f6;
          padding: 15px 11px 15px 11px;
        }
      }
    }
    /// modal summary order ///
  }

  #modal-check-tranfer {
    .wrap-modal {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .item-left {
        width: 55%;
        padding: 0 40px 0 20px;
      }
      .item-right {
        width: 45%;
        padding: 0 20px 0 40px;
        position: relative;
        .wrap-footer {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .image-slip {
      width: 300px;
      height: 300px;
    }

    .image-slip-preview {
      width: 532px;
      height: 532px;
      position: absolute;
      z-index: 9999;
      left: 140px;
      top: 184px;
    }
    .box-list-menu {
      width: 100%;
      border-radius: 5px;
      border: 2px solid $secondaryColor;
      height: 204px;
      // overflow-x: scroll;
      .inner {
        padding: 5px;
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
      }
      .inner::-webkit-scrollbar {
        width: 0.5rem;
        border-radius: 5px;
      }
      .inner::-webkit-scrollbar,
      .tinner::-webkit-scrollbar-thumb {
        overflow: visible;
        border-radius: 5px;
        background: $secondaryColor;
      }
      .inner::-webkit-scrollbar-thumb {
        background: #2f80ed;
        border: 1px solid #2f80ed;
        z-index: 9999999999999;
        border-radius: 5px;
      }
      .cover-code {
        position: absolute;
        background: #ffffff;
        height: 185px;
        top: 42px;
        right: 30px;
        width: 0.5rem;
        -webkit-transition: all 0.5s;
        opacity: 1;
      }
      .inner {
        &:hover {
          .cover-code {
            opacity: 0;
            -webkit-transition: all 0.5s;
          }
        }
      }
    }

    .border-left-mdoal {
      border-left: 1px solid $Grey;
      padding-left: 30px;
    }

    .icon-previous {
      position: absolute;
      top: 55px;
      left: -38px;
    }

    .dot-inactive {
      height: 10px;
      width: 10px;
      background-color: $Darkgrey;
      border-radius: 50%;
      display: inline-block;
      margin-right: 2px;
    }

    .dot-active {
      height: 10px;
      width: 10px;
      background-color: $primaryColor;
      border-radius: 50%;
      display: inline-block;
      margin-right: 2px;
    }

    .icon-next {
      position: absolute;
      right: -44px;
      top: 55px;
    }

    #style-2::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      background-color: #e8ebff;
    }

    #style-2::-webkit-scrollbar {
      width: 8px;
      background-color: #e8ebff;
    }

    #style-2::-webkit-scrollbar-thumb {
      border-radius: 5px;
      // -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
      background-color: $blueFun;
    }

    .input-code {
      // width: 200px;
      // height: 20px;
      padding-right: 107px;
    }

    .button-code {
      padding-top: 3px;
      margin-left: -106px;
      height: 30px;
      width: 102px;
      // background: $primaryColor;
      color: white;
      border: 0;
      -webkit-appearance: none;
      margin-top: 12px;
      margin-right: -2px;
      img {
        margin-bottom: 3px;
        margin-left: 2px;
      }
    }
    .box-button-confirm {
      width: 100%;
      position: absolute;
      bottom: 22px;
      padding: 0 20px 0 40px;
      left: 0px;
      // right: 40px;
    }
  }

  #modal-preview-history {
    .dot-inactive {
      height: 10px;
      width: 10px;
      background-color: $Darkgrey;
      border-radius: 50%;
      display: inline-block;
      margin-right: 2px;
    }

    .dot-active {
      height: 10px;
      width: 10px;
      background-color: $primaryColor;
      border-radius: 50%;
      display: inline-block;
      margin-right: 2px;
    }

    .image-slip-preview {
      width: 532px;
      height: 532px;
      position: absolute;
      z-index: 9999;
      left: 100px;
      top: 35px;
    }
  }
}

#modal-extraData {
  color: black;
  max-width: 950px;
  @media only screen and (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 950px;
    }
  }

  .modal-header {
    background-color: #233b9a;
    color: white;
  }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: white;
  }
  .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 14px;
  }

  .modal-body {
    padding: 1rem 1rem 2rem 1rem;
  }

  // p {
  //   color: #828282;
  // }

  span {
    color: #828282;
  }

  .card-image {
    width: 33%;
    padding: 3px;
    border-radius: 12px;
  }

  .card-img-top {
    width: 100%;
    border-radius: 12px;
  }

  #detail-menu {
    div {
      padding: 15px 10px 15px 40px;
    }

    hr {
      width: 92%;
    }

    #more-detail-callcenter {

      .text-area-detail {
        background: #f6f6f6;
        border: 1px solid #e8ebff;
        box-sizing: border-box;
        border-radius: 5px;
        width: 100%;
      }

      .btn-submit {
        background: #004ec2;
        border-radius: 5px;
        color:white;
        width: 7rem;
      }

      .font-size-12px{
        font-size: 12px;
      }
    }
  }

  .btn-download {
    width: 11rem;
    height: 3em;
    background-color: #004EC2;
    border-radius: 5px;
    color: #fff;
    border: none;
  }
}
