#table-component {
  .thead-custom {
    background-color: #cdd4ff;
    border: 3px solid #cdd4ff;
    // border-bottom: none;
  }
  .tr-odd {
    background-color: #FFFFFF;
  }
  .tr-even {
    background-color: #F0F1F9;
    border: 3px solid #F0F1F9;
  }
}
