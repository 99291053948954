::-webkit-scrollbar {
  width: 0.4em;
}
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}

.btn-primary-sr {
  color: #ffffff !important;
  background-color: $primaryColor;
  border-color: $primaryColor;
  border-radius: 5px;
  &:hover {
    color: rgba(255, 255, 255, 0.65) !important;
  }
}

.btn-white {
  color: $primaryColor !important;
  background-color: white;
  border-color: $primaryColor;
  border-radius: 5px;
  &:hover {
    color: $primaryColor !important;
  }
}

.word-break-word {
  word-break: break-word;
}
.container-main {
  height: 100vh;
  overflow: hidden;
}
.component-shop {
  height: 73vh;
  overflow-y: scroll;
  overflow-x: hidden;
  // position: relative;
  .shop {
    padding-top: 15px;
    &:first-child {
      padding-top: 0;
    }
  }
}
.component-shop::-webkit-scrollbar {
  width: $unit5;
}
.component-shop::-webkit-scrollbar,
.component-shop::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}
.component-shop::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.component-shop {
  .cover-shop {
    position: absolute;
    background: #ffffff;
    height: 76vh;
    top: 89px;
    right: 10px;
    width: $unit10;
    -webkit-transition: all 0.5s;
    opacity: 1;
    z-index: 9;
    border: 1px solid transparent;
  }
  &:hover {
    .cover-shop {
      opacity: 0;
      -webkit-transition: all 0.5s;
    }
  }
}
.header-shop {
  display: flex;
  flex-direction: row;
  padding-bottom: $unit15;
  .topic {
    font-size: 24px;
    color: $Black;
    margin-bottom: 0;
  }
  .name {
    font-weight: bold;
    font-size: 24px;
    color: $primaryColor;
    margin-bottom: 0;
    padding-left: $unit15;
  }
}
.header-menu-name {
  font-size: 16px;
  color: $Black;
  margin-bottom: 0;
}
.font-box-select-date {
  cursor: pointer;
}
._text-warning {
  color: $Error;
  font-size: 14px;
  text-align: center;
  padding: 5px 0;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.font-color-primary {
  color: $primary;
}

.bg-white {
  background-color: #ffffff;
}

.font-color-error {
  color: $Error;
}

.font-size-20px {
  font-size: 20px;
  font-style: normal;
}

.width-90per {
  width: 90%;
}

.width-33per {
  width: 33.33%;
}

.font-bold-size-20px {
  font-size: 20px;
  font-weight: bold;
}

.font-size-16px {
  font-size: 16px;
  font-weight: normal;
}

.font-size-14px {
  font-size: 14px;
  font-weight: normal;
}

.font-primary-14px-text {
  font-size: 14px;
  color: $primary;
}

.font-bold-size-16px {
  font-size: 16px;
  font-weight: bold;
}

.font-bold-primary-size-20px {
  font-size: 20px;
  font-weight: bold;
  color: $primary;
}

.font-primary-16px-text {
  font-size: 16px;
  color: $primary;
}

.font-primary-bold-size-16px {
  font-size: 16px;
  font-weight: bold;
  color: $primary;
}

.font-primary-32px-text {
  font-size: 32px;
  line-height: 32px;
  font-weight: normal;
  color: $Black;
}

.font-primary-blob-32px-text {
  font-size: 32px;
  line-height: 32px;
  color: $primary;
  font-weight: bold;
}

.font-color-white {
  color: white;
}

.font-color-black-size-16px {
  color: black;
  font-size: 16px;
}

.size-icon-20px {
  width: 20px;
  height: 20px;
}

.size-icon-add {
  width: 28px;
  height: 28px;
}

.size-icon-25px {
  widows: 25px;
  height: 25px;
}

.ml-2px {
  margin-left: 2px;
}

.ml-21px {
  margin-left: 21px;
}

.cursor-pointer {
  cursor: pointer;
}

.border-radius {
  border-radius: 8px;
}

.line-hr {
  border: 0.6px solid $E8EBFF;
}

.input-background-color {
  background: #f6f6f6;
}

.btn-primary {
  background-color: $primary;
  color: white;
  &:hover {
    color: white;
  }
}

.btn-darkgrey {
  background-color: $Darkgrey;
  color: white;
  &:hover {
    color: white;
  }
}

.mt-10per {
  margin-top: 10%;
}

.mt-5per {
  margin-top: 5%;
}

.mt-2per {
  margin-top: 2%;
}

.width-24px {
  width: 24px;
}

.border-color-error {
  border-color: $Error;
}

.input-background-white {
  background-color: white;
}

.size-icon-16px {
  width: 16px;
  height: 16px;
}

.size-icon-20px {
  width: 20px;
  height: 20px;
}

.mt-15px {
  margin-top: 15px;
}

.mt-19px {
  margin-top: 19px;
}

.mt-50px {
  margin-top: 50px;
}

.container-box-custom {
  width: 100%;
  height: 100vh;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 15px;
}

.padding-40px {
  padding: 40px;
}

.input-search-purchase {
  background: url(../assets/Icons/SearchPrimary.svg) no-repeat scroll 7px 7px;
  padding-left: 40px;
  background-size: 20px;
}

.height-33px {
  height: 33px;
}

.box-preview-remark {
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  background-color: #f6f6f6;
  height: 100px;
  width: 100%;
}

.height-60vh {
  height: 60vh;
}

.corlor-darkgrey {
  color: $Darkgrey;
}

.icon-close-search-purchase {
  float: right;
  margin-right: 6px;
  margin-top: -27px;
  position: relative;
  z-index: 2;
}
.nav-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  i {
    background-color: #ffffff;
    min-width: 2.5rem;
    min-height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    font-size: 2rem;
    border: 1px solid $primary;
    margin-right: 15px;
  }
  a {
    &:hover {
      height: 3rem;
      width: 7rem;
    }

    // padding: 0 !important;
  }
  .nav-menu {
    height: 3rem;
    width: 7rem;
    display: flex;
    align-items: center;
    padding: 0px;
    &:hover {
      padding: 0;
    }
  }
  a {
    label {
      margin-bottom: 0;
    }
  }
}

.radio-custom-modal {
  opacity: 0;
  position: absolute;
}

.radio-custom-modal,
.radio-custom-modal-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radio-custom-modal-label {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  // padding-left: 35px;
}

.radio-custom-modal + .radio-custom-modal-label:before {
  font-family: "fontello";
  content: "\f111";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  color: #e8ebff;
  border-radius: 50%;
  position: absolute;
}

.radio-custom-modal:checked + :before {
  font-family: "fontello";
  content: "\e800";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  color: $primaryColor;
  position: absolute;
}

.checkbox-custom-modal {
  opacity: 0;
  position: absolute;
}

.checkbox-custom-modal,
.checkbox-custom-modal-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-custom-modal-label {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  // padding-left: 35px;
}

.checkbox-custom-modal + .checkbox-custom-modal-label:before {
  font-family: "fontello";
  content: "\f0c8";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  color: #e8ebff;
  border-radius: 50%;
  position: absolute;
}

.checkbox-custom-modal:checked + :before {
  font-family: "fontello";
  content: "\f14a";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  color: $primaryColor;
  position: absolute;
}

.mini-text {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fit-to-parent {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.canvas-cover-orientation {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

//spinner number disable
     /* For Firefox */
input[type='number'] {
  -moz-appearance:textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
//spinner number disable

