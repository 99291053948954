.logo {
  width: 74px;
  height: 45px;
  margin-left: 2rem;
}

.nav-bar {
  font-size: 20px;
  margin-left: 1rem;
  color: black;
}

.mt-2rem {
  margin-right: 2rem;
}

.nav-menu {
  height: 4rem;
  width: 9rem;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.nav-menu:hover {
  height: 4rem;
  width: 9rem;
  display: flex;
  background-color: #e8ebff;
  justify-content: center;
  padding-top: 1rem;
}

.nav-menu-active {
  height: 4rem;
  width: 9rem;
  display: flex;
  background-color: #e8ebff;
  justify-content: center;
  padding-top: 1rem;
}

.padding-0 {
  padding: 0px;
}

.list-menu-text {
  font-size: 16px;
  line-height: 21px;
  color: #333333;
}

.header-active {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: $primary;
}

.header-inactive {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
}
