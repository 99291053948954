@import url(https://fonts.googleapis.com/css?family=Sarabun:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sarabun:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sarabun:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sarabun:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sarabun:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sarabun:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Sarabun:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i&display=swap);
:root {
  font-size: 16px; }

.pt-20 {
  padding-top: 1.25rem !important; }

.pb-20 {
  padding-bottom: 1.25rem !important; }

.pl-20 {
  padding-left: 1.25rem !important; }

.pr-20 {
  padding-right: 1.25rem !important; }

.pt-15 {
  padding-top: 0.938rem !important; }

.pb-15 {
  padding-bottom: 0.938rem !important; }

.pl-15 {
  padding-left: 0.938rem !important; }

.pr-15 {
  padding-right: 0.938rem !important; }

.pt-10 {
  padding-top: 0.625rem !important; }

.pb-10 {
  padding-bottom: 0.625rem !important; }

.pl-10 {
  padding-left: 0.625rem !important; }

.pr-10 {
  padding-right: 0.625rem !important; }

.pt-05 {
  padding-top: 0.313rem !important; }

.pb-05 {
  padding-bottom: 0.313rem !important; }

.pl-05 {
  padding-left: 0.313rem !important; }

.pr-05 {
  padding-right: 0.313rem !important; }

.mt-20 {
  margin-top: 1.25rem !important; }

.mb-20 {
  margin-bottom: 1.25rem !important; }

.ml-20 {
  margin-left: 1.25rem !important; }

.mr-20 {
  margin-right: 1.25rem !important; }

.mt-15 {
  margin-top: 0.938rem !important; }

.mb-15 {
  margin-bottom: 0.938rem !important; }

.ml-15 {
  margin-left: 0.938rem !important; }

.mr-15 {
  margin-right: 0.938rem !important; }

.mt-10 {
  margin-top: 0.625rem !important; }

.mb-10 {
  margin-bottom: 0.625rem !important; }

.ml-10 {
  margin-left: 0.625rem !important; }

.mr-10 {
  margin-right: 0.625rem !important; }

.mt-05 {
  margin-top: 0.313rem !important; }

.mb-05 {
  margin-bottom: 0.313rem !important; }

.ml-05 {
  margin-left: 0.313rem !important; }

.mr-05 {
  margin-right: 0.313rem !important; }

body {
  font-family: "Sarabun", sans-serif !important; }

.btn.focus, .btn:focus {
  box-shadow: none !important; }

::-webkit-scrollbar {
  width: 0.4em; }

::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); }

.btn-primary-sr {
  color: #ffffff !important;
  background-color: #004EC2;
  border-color: #004EC2;
  border-radius: 5px; }
  .btn-primary-sr:hover {
    color: rgba(255, 255, 255, 0.65) !important; }

.btn-white {
  color: #004EC2 !important;
  background-color: white;
  border-color: #004EC2;
  border-radius: 5px; }
  .btn-white:hover {
    color: #004EC2 !important; }

.word-break-word {
  word-break: break-word; }

.container-main {
  height: 100vh;
  overflow: hidden; }

.component-shop {
  height: 73vh;
  overflow-y: scroll;
  overflow-x: hidden; }
  .component-shop .shop {
    padding-top: 15px; }
    .component-shop .shop:first-child {
      padding-top: 0; }

.component-shop::-webkit-scrollbar {
  width: 0.313rem; }

.component-shop::-webkit-scrollbar,
.component-shop::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

.component-shop::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  z-index: 9; }

.component-shop .cover-shop {
  position: absolute;
  background: #ffffff;
  height: 76vh;
  top: 89px;
  right: 10px;
  width: 0.625rem;
  -webkit-transition: all 0.5s;
  opacity: 1;
  z-index: 9;
  border: 1px solid transparent; }

.component-shop:hover .cover-shop {
  opacity: 0;
  -webkit-transition: all 0.5s; }

.header-shop {
  display: flex;
  flex-direction: row;
  padding-bottom: 0.938rem; }
  .header-shop .topic {
    font-size: 24px;
    color: #131414;
    margin-bottom: 0; }
  .header-shop .name {
    font-weight: bold;
    font-size: 24px;
    color: #004EC2;
    margin-bottom: 0;
    padding-left: 0.938rem; }

.header-menu-name {
  font-size: 16px;
  color: #131414;
  margin-bottom: 0; }

.font-box-select-date {
  cursor: pointer; }

._text-warning {
  color: #E94D4D;
  font-size: 14px;
  text-align: center;
  padding: 5px 0; }

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.font-color-primary {
  color: #004EC2; }

.bg-white {
  background-color: #ffffff; }

.font-color-error {
  color: #E94D4D; }

.font-size-20px {
  font-size: 20px;
  font-style: normal; }

.width-90per {
  width: 90%; }

.width-33per {
  width: 33.33%; }

.font-bold-size-20px {
  font-size: 20px;
  font-weight: bold; }

.font-size-16px {
  font-size: 16px;
  font-weight: normal; }

.font-size-14px {
  font-size: 14px;
  font-weight: normal; }

.font-primary-14px-text {
  font-size: 14px;
  color: #004EC2; }

.font-bold-size-16px {
  font-size: 16px;
  font-weight: bold; }

.font-bold-primary-size-20px {
  font-size: 20px;
  font-weight: bold;
  color: #004EC2; }

.font-primary-16px-text {
  font-size: 16px;
  color: #004EC2; }

.font-primary-bold-size-16px {
  font-size: 16px;
  font-weight: bold;
  color: #004EC2; }

.font-primary-32px-text {
  font-size: 32px;
  line-height: 32px;
  font-weight: normal;
  color: #131414; }

.font-primary-blob-32px-text {
  font-size: 32px;
  line-height: 32px;
  color: #004EC2;
  font-weight: bold; }

.font-color-white {
  color: white; }

.font-color-black-size-16px {
  color: black;
  font-size: 16px; }

.size-icon-20px {
  width: 20px;
  height: 20px; }

.size-icon-add {
  width: 28px;
  height: 28px; }

.size-icon-25px {
  widows: 25px;
  height: 25px; }

.ml-2px {
  margin-left: 2px; }

.ml-21px {
  margin-left: 21px; }

.cursor-pointer {
  cursor: pointer; }

.border-radius {
  border-radius: 8px; }

.line-hr {
  border: 0.6px solid #E8EBFF; }

.input-background-color {
  background: #f6f6f6; }

.btn-primary {
  background-color: #004EC2;
  color: white; }
  .btn-primary:hover {
    color: white; }

.btn-darkgrey {
  background-color: #828282;
  color: white; }
  .btn-darkgrey:hover {
    color: white; }

.mt-10per {
  margin-top: 10%; }

.mt-5per {
  margin-top: 5%; }

.mt-2per {
  margin-top: 2%; }

.width-24px {
  width: 24px; }

.border-color-error {
  border-color: #E94D4D; }

.input-background-white {
  background-color: white; }

.size-icon-16px {
  width: 16px;
  height: 16px; }

.size-icon-20px {
  width: 20px;
  height: 20px; }

.mt-15px {
  margin-top: 15px; }

.mt-19px {
  margin-top: 19px; }

.mt-50px {
  margin-top: 50px; }

.container-box-custom {
  width: 100%;
  height: 100vh;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  padding-top: 15px; }

.padding-40px {
  padding: 40px; }

.input-search-purchase {
  background: url(/static/media/SearchPrimary.8109df6e.svg) no-repeat scroll 7px 7px;
  padding-left: 40px;
  background-size: 20px; }

.height-33px {
  height: 33px; }

.box-preview-remark {
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  background-color: #f6f6f6;
  height: 100px;
  width: 100%; }

.height-60vh {
  height: 60vh; }

.corlor-darkgrey {
  color: #828282; }

.icon-close-search-purchase {
  float: right;
  margin-right: 6px;
  margin-top: -27px;
  position: relative;
  z-index: 2; }

.nav-header {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .nav-header i {
    background-color: #ffffff;
    min-width: 2.5rem;
    min-height: 2.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #004EC2;
    font-size: 2rem;
    border: 1px solid #004EC2;
    margin-right: 15px; }
  .nav-header a:hover {
    height: 3rem;
    width: 7rem; }
  .nav-header .nav-menu {
    height: 3rem;
    width: 7rem;
    display: flex;
    align-items: center;
    padding: 0px; }
    .nav-header .nav-menu:hover {
      padding: 0; }
  .nav-header a label {
    margin-bottom: 0; }

.radio-custom-modal {
  opacity: 0;
  position: absolute; }

.radio-custom-modal,
.radio-custom-modal-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer; }

.radio-custom-modal-label {
  position: relative;
  width: 100%;
  margin-bottom: 0; }

.radio-custom-modal + .radio-custom-modal-label:before {
  font-family: "fontello";
  content: "\f111";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  color: #e8ebff;
  border-radius: 50%;
  position: absolute; }

.radio-custom-modal:checked + :before {
  font-family: "fontello";
  content: "\e800";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  color: #004EC2;
  position: absolute; }

.checkbox-custom-modal {
  opacity: 0;
  position: absolute; }

.checkbox-custom-modal,
.checkbox-custom-modal-label {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer; }

.checkbox-custom-modal-label {
  position: relative;
  width: 100%;
  margin-bottom: 0; }

.checkbox-custom-modal + .checkbox-custom-modal-label:before {
  font-family: "fontello";
  content: "\f0c8";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  color: #e8ebff;
  border-radius: 50%;
  position: absolute; }

.checkbox-custom-modal:checked + :before {
  font-family: "fontello";
  content: "\f14a";
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0;
  color: #004EC2;
  position: absolute; }

.mini-text {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis; }

.fit-to-parent {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain; }

.canvas-cover-orientation {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; }

/* For Firefox */
input[type='number'] {
  -moz-appearance: textfield; }

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

html {
  height: 100%; }

body {
  background-color: #e5e5e5;
  height: 100%; }

#header {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999; }

#sidebar-left {
  float: left;
  width: 15rem;
  background-color: white;
  height: 100vh;
  position: fixed;
  left: 0; }

#main {
  padding: 15px;
  width: 100%;
  height: 100%;
  background-color: white;
  padding-left: 16rem; }

#main-extra {
  padding: 15px;
  width: 100%;
  height: 100%;
  background-color: #e5e5e5;
  padding-left: 16rem; }

#footer {
  clear: both;
  height: 50px;
  width: 100%;
  text-align: center;
  background-color: lightblue; }

#modal-main-compenent .modal-main-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px 2px 20px; }
  #modal-main-compenent .modal-main-header .text-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 26px; }
  #modal-main-compenent .modal-main-header .icon-close {
    position: absolute;
    top: 20px;
    right: 20px; }

#modal-main-compenent .modal-main-body::-webkit-scrollbar {
  width: 0.313rem; }

#modal-main-compenent .modal-main-body::-webkit-scrollbar,
#modal-main-compenent .modal-main-body::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

#modal-main-compenent .modal-main-body::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); }

#modal-main-compenent .cover-bar-modal {
  position: absolute;
  background: #ffffff;
  height: 100%;
  top: 0;
  right: 1px;
  width: 0.938rem;
  -webkit-transition: all 0.5s;
  border-radius: 0.3rem;
  opacity: 1; }

#modal-main-compenent .modal-main-body:hover .cover-bar-modal {
  opacity: 0;
  -webkit-transition: all 0.5s; }

#modal-main-compenent .modal-main-body {
  padding: 0px 20px 20px 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 85vh;
  width: 100%; }
  #modal-main-compenent .modal-main-body .box-list-address-user .wrap-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%; }
    #modal-main-compenent .modal-main-body .box-list-address-user .wrap-list .address {
      width: 100%;
      padding-bottom: 0.625rem; }
    #modal-main-compenent .modal-main-body .box-list-address-user .wrap-list .trash {
      width: 22px;
      height: 28px; }
      #modal-main-compenent .modal-main-body .box-list-address-user .wrap-list .trash img {
        width: 20px;
        height: 20px; }
    #modal-main-compenent .modal-main-body .box-list-address-user .wrap-list .more-details {
      width: 100%;
      padding-bottom: 0.938rem; }
    #modal-main-compenent .modal-main-body .box-list-address-user .wrap-list .footer-check {
      width: 100%;
      padding-bottom: 0.938rem; }
    #modal-main-compenent .modal-main-body .box-list-address-user .wrap-list .check-box {
      display: flex;
      align-items: center; }
      #modal-main-compenent .modal-main-body .box-list-address-user .wrap-list .check-box label {
        margin-bottom: 0;
        padding-left: 0.625rem; }
  #modal-main-compenent .modal-main-body .column-list-modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
    padding-left: 0.938rem; }
  #modal-main-compenent .modal-main-body .box-list-address-inactive {
    border: 1px solid #e8ebff;
    border-radius: 5px;
    padding: 0.938rem;
    width: 100%;
    overflow-y: scroll;
    /* showing scrollbars */
    height: 151px;
    display: flex;
    flex-direction: row; }
    #modal-main-compenent .modal-main-body .box-list-address-inactive .check {
      width: 16px;
      height: 16px; }
  #modal-main-compenent .modal-main-body .box-list-address-inactive::-webkit-scrollbar {
    width: 0.313rem; }
  #modal-main-compenent .modal-main-body .box-list-address-inactive::-webkit-scrollbar,
  #modal-main-compenent .modal-main-body .box-list-address-inactive::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 4px; }
  #modal-main-compenent .modal-main-body .box-list-address-inactive::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    z-index: 99; }
  #modal-main-compenent .modal-main-body .box-list-address-inactive .cover-bar {
    position: absolute;
    background: #ffffff;
    height: 96%;
    top: 3px;
    right: 1px;
    width: 0.938rem;
    -webkit-transition: all 0.5s;
    opacity: 1;
    z-index: 9999999;
    border: 1px solid transparent; }
  #modal-main-compenent .modal-main-body .box-list-address-inactive:hover .cover-bar {
    opacity: 0;
    -webkit-transition: all 0.5s; }
  #modal-main-compenent .modal-main-body .box-list-address-active::-webkit-scrollbar {
    width: 0.313rem;
    background: #e8ebff; }
  #modal-main-compenent .modal-main-body .box-list-address-active::-webkit-scrollbar,
  #modal-main-compenent .modal-main-body .box-list-address-active::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 4px; }
  #modal-main-compenent .modal-main-body .box-list-address-active::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    z-index: 99;
    margin: 3px 0; }
  #modal-main-compenent .modal-main-body .box-list-address-active .cover-bar {
    position: absolute;
    background: #e8ebff;
    height: 96%;
    top: 3px;
    right: 1px;
    width: 0.938rem;
    -webkit-transition: all 0.5s;
    opacity: 1;
    z-index: 9999999; }
  #modal-main-compenent .modal-main-body .box-list-address-active:hover .cover-bar {
    opacity: 0;
    -webkit-transition: all 0.5s; }
  #modal-main-compenent .modal-main-body .box-list-address-active {
    border: 1px solid #e8ebff;
    border-radius: 5px;
    padding: 0.938rem;
    background-color: #e8ebff;
    width: 100%;
    overflow-y: scroll;
    /* showing scrollbars */
    height: 151px;
    display: flex;
    flex-direction: row; }
    #modal-main-compenent .modal-main-body .box-list-address-active .check {
      width: 16px;
      height: 16px; }
  #modal-main-compenent .modal-main-body .text-address-default {
    background-color: #cdd4ff;
    color: #004EC2;
    width: 65px; }
  #modal-main-compenent .modal-main-body .box-show-summary-order {
    padding: 10px 50px 10px 50px; }
    #modal-main-compenent .modal-main-body .box-show-summary-order .box-message {
      width: 100%; }
      #modal-main-compenent .modal-main-body .box-show-summary-order .box-message .header {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #e8ebff;
        border-radius: 5px 5px 0px 0px;
        height: 61px; }
      #modal-main-compenent .modal-main-body .box-show-summary-order .box-message .content {
        background-color: #f6f6f6;
        padding: 15px 11px 15px 11px; }

#modal-main-compenent #modal-check-tranfer .wrap-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; }
  #modal-main-compenent #modal-check-tranfer .wrap-modal .item-left {
    width: 55%;
    padding: 0 40px 0 20px; }
  #modal-main-compenent #modal-check-tranfer .wrap-modal .item-right {
    width: 45%;
    padding: 0 20px 0 40px;
    position: relative; }
    #modal-main-compenent #modal-check-tranfer .wrap-modal .item-right .wrap-footer {
      display: flex;
      flex-direction: column; }

#modal-main-compenent #modal-check-tranfer .image-slip {
  width: 300px;
  height: 300px; }

#modal-main-compenent #modal-check-tranfer .image-slip-preview {
  width: 532px;
  height: 532px;
  position: absolute;
  z-index: 9999;
  left: 140px;
  top: 184px; }

#modal-main-compenent #modal-check-tranfer .box-list-menu {
  width: 100%;
  border-radius: 5px;
  border: 2px solid #E8EBFF;
  height: 204px; }
  #modal-main-compenent #modal-check-tranfer .box-list-menu .inner {
    padding: 5px;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden; }
  #modal-main-compenent #modal-check-tranfer .box-list-menu .inner::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 5px; }
  #modal-main-compenent #modal-check-tranfer .box-list-menu .inner::-webkit-scrollbar,
  #modal-main-compenent #modal-check-tranfer .box-list-menu .tinner::-webkit-scrollbar-thumb {
    overflow: visible;
    border-radius: 5px;
    background: #E8EBFF; }
  #modal-main-compenent #modal-check-tranfer .box-list-menu .inner::-webkit-scrollbar-thumb {
    background: #2f80ed;
    border: 1px solid #2f80ed;
    z-index: 9999999999999;
    border-radius: 5px; }
  #modal-main-compenent #modal-check-tranfer .box-list-menu .cover-code {
    position: absolute;
    background: #ffffff;
    height: 185px;
    top: 42px;
    right: 30px;
    width: 0.5rem;
    -webkit-transition: all 0.5s;
    opacity: 1; }
  #modal-main-compenent #modal-check-tranfer .box-list-menu .inner:hover .cover-code {
    opacity: 0;
    -webkit-transition: all 0.5s; }

#modal-main-compenent #modal-check-tranfer .border-left-mdoal {
  border-left: 1px solid #E0E0E0;
  padding-left: 30px; }

#modal-main-compenent #modal-check-tranfer .icon-previous {
  position: absolute;
  top: 55px;
  left: -38px; }

#modal-main-compenent #modal-check-tranfer .dot-inactive {
  height: 10px;
  width: 10px;
  background-color: #828282;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px; }

#modal-main-compenent #modal-check-tranfer .dot-active {
  height: 10px;
  width: 10px;
  background-color: #004EC2;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px; }

#modal-main-compenent #modal-check-tranfer .icon-next {
  position: absolute;
  right: -44px;
  top: 55px; }

#modal-main-compenent #modal-check-tranfer #style-2::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #e8ebff; }

#modal-main-compenent #modal-check-tranfer #style-2::-webkit-scrollbar {
  width: 8px;
  background-color: #e8ebff; }

#modal-main-compenent #modal-check-tranfer #style-2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #2F80ED; }

#modal-main-compenent #modal-check-tranfer .input-code {
  padding-right: 107px; }

#modal-main-compenent #modal-check-tranfer .button-code {
  padding-top: 3px;
  margin-left: -106px;
  height: 30px;
  width: 102px;
  color: white;
  border: 0;
  -webkit-appearance: none;
  margin-top: 12px;
  margin-right: -2px; }
  #modal-main-compenent #modal-check-tranfer .button-code img {
    margin-bottom: 3px;
    margin-left: 2px; }

#modal-main-compenent #modal-check-tranfer .box-button-confirm {
  width: 100%;
  position: absolute;
  bottom: 22px;
  padding: 0 20px 0 40px;
  left: 0px; }

#modal-main-compenent #modal-preview-history .dot-inactive {
  height: 10px;
  width: 10px;
  background-color: #828282;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px; }

#modal-main-compenent #modal-preview-history .dot-active {
  height: 10px;
  width: 10px;
  background-color: #004EC2;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px; }

#modal-main-compenent #modal-preview-history .image-slip-preview {
  width: 532px;
  height: 532px;
  position: absolute;
  z-index: 9999;
  left: 100px;
  top: 35px; }

#modal-extraData {
  color: black;
  max-width: 950px; }
  @media only screen and (min-width: 992px) {
    #modal-extraData .modal-lg,
    #modal-extraData .modal-xl {
      max-width: 950px; } }
  #modal-extraData .modal-header {
    background-color: #233b9a;
    color: white; }
  #modal-extraData .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto;
    color: white; }
  #modal-extraData .modal-title {
    margin-bottom: 0;
    line-height: 1.5;
    font-size: 14px; }
  #modal-extraData .modal-body {
    padding: 1rem 1rem 2rem 1rem; }
  #modal-extraData span {
    color: #828282; }
  #modal-extraData .card-image {
    width: 33%;
    padding: 3px;
    border-radius: 12px; }
  #modal-extraData .card-img-top {
    width: 100%;
    border-radius: 12px; }
  #modal-extraData #detail-menu div {
    padding: 15px 10px 15px 40px; }
  #modal-extraData #detail-menu hr {
    width: 92%; }
  #modal-extraData #detail-menu #more-detail-callcenter .text-area-detail {
    background: #f6f6f6;
    border: 1px solid #e8ebff;
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%; }
  #modal-extraData #detail-menu #more-detail-callcenter .btn-submit {
    background: #004ec2;
    border-radius: 5px;
    color: white;
    width: 7rem; }
  #modal-extraData #detail-menu #more-detail-callcenter .font-size-12px {
    font-size: 12px; }
  #modal-extraData .btn-download {
    width: 11rem;
    height: 3em;
    background-color: #004EC2;
    border-radius: 5px;
    color: #fff;
    border: none; }

#modal-alert-component {
  padding: 10px; }
  #modal-alert-component .header {
    margin-top: 35px;
    display: flex;
    justify-content: center; }
  #modal-alert-component .body {
    margin-top: 20px;
    text-align: center; }
  #modal-alert-component .footer {
    margin-top: 20px;
    display: flex;
    justify-content: center; }
    #modal-alert-component .footer .btn-footer {
      width: 14rem; }

#notification-component .notification {
  border-top: none !important; }

#notification-component .notifications-tr {
  width: 375px !important; }

#notification-component .notification-error {
  background-color: #ffcbcb !important; }
  #notification-component .notification-error .notification-message {
    color: #e94d4d !important; }
  #notification-component .notification-error .notification-dismiss {
    font-size: 27px !important;
    width: 20px !important;
    height: 20px !important;
    margin-top: 10px;
    background-color: #ffcbcb !important;
    color: #c4c4c4 !important; }

#select-option-component .css-1pahdxg-control {
  box-shadow: none !important;
  background-color: #f6f6f6 !important;
  border-color: #E8EBFF !important; }

#select-option-component .css-yk16xz-control {
  background-color: #f6f6f6 !important;
  border-color: #E8EBFF !important; }

#select-option-component .css-1okebmr-indicatorSeparator {
  display: none !important; }

#loading-component .wrap-loading {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  overflow-x: hidden;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  text-align: center;
  z-index: 100000; }

#loading-component .wrap-icon-loading {
  width: 100%;
  height: 100vh;
  padding-top: 32vh;
  -webkit-transition: 0.2s;
  transition: 0.2s; }

#loading-component .wrap-icon-loading i {
  color: #ffffff; }

#loading-component .loading-box {
  width: 252px;
  height: 80px;
  background: #cdd4ff;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center; }

#loading-component .loading-text {
  margin-left: 10px;
  font-weight: bold;
  font-size: 32px;
  line-height: 42px;
  color: #004EC2; }

#loading-component .text-primary {
  color: #004EC2 !important; }

#pagination-component .pagination .page-item {
  margin-left: 5px;
  margin-right: 5px; }
  #pagination-component .pagination .page-item .page-link {
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    color: black;
    font-weight: bold; }
    #pagination-component .pagination .page-item .page-link span {
      color: #004EC2 !important; }

#pagination-component .pagination .page-item.active .page-link {
  background-color: #004EC2 !important;
  color: white; }

#box-order-history {
  overflow: scroll;
  background-color: #F6F6F6;
  padding: 25px;
  border-radius: 5px; }
  #box-order-history .box-order {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 8px;
    width: 100%; }

.background-modalhistory {
  padding: 15px;
  background-color: #f6f6f6;
  width: 18rem;
  height: 21rem;
  overflow: scroll;
  border: 1px solid #cdd4ff; }

.background-listhistory {
  background-color: white;
  padding: 10px; }

@media only screen and (min-width: 992px) {
  div.line-center {
    border-left: 1px solid #e0e0e0;
    height: 90%;
    position: absolute;
    left: 56%;
    margin-left: -3px;
    top: -25px;
    margin-top: 12%; } }

.overflow-history {
  overflow: scroll; }

.tabactive-history {
  color: #004EC2; }

.tabinactive-history {
  background-color: #e0e0e0;
  color: #828282; }

.list-invoice-waitpayment {
  overflow: scroll;
  height: 36rem; }

.text-area-note {
  width: 23rem;
  height: 98px;
  border-radius: 5px;
  background-color: #f6f6f6;
  resize: none;
  border: 1px solid #e8ebff;
  outline: none;
  color: #828282; }

.icon-purchase-note {
  width: 10px;
  position: relative;
  top: 35%; }

#extra-data {
  margin: 0 auto;
  padding: 0 70px 0px 0px;
  background-color: #fff;
  padding: 2em 5em; }
  #extra-data p .text-update {
    color: #ACACAC; }
  #extra-data .full-width {
    width: 100%; }
  #extra-data .search-component {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    margin-bottom: 3px; }
  #extra-data .sort {
    width: 30%;
    margin: 30px 0px; }
  #extra-data .sort-text {
    flex: none;
    margin: 0px 10px; }
  #extra-data .search-item {
    flex-direction: row; }
  #extra-data .search-select {
    margin: 0px 10px; }
  #extra-data .icon-check {
    width: 20px;
    height: 20px;
    margin: 0px 5px; }
  #extra-data .btn-download {
    background-color: #004EC2;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    width: 200px;
    height: 50px;
    color: #fff; }
  #extra-data .btn-download:disabled {
    background-color: #e0e0e0;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    width: 200px;
    height: 50px;
    color: #fff; }
  #extra-data .shop {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    height: 60vh; }
  #extra-data .img-icon {
    width: 70px;
    border-radius: 50%; }
  #extra-data .text-update {
    color: #ACACAC;
    font-size: 9px; }
  #extra-data .flex-response {
    flex-direction: row; }
  @media only screen and (max-width: 700px) {
    #extra-data .flex-response {
      flex-direction: column; }
    #extra-data .sort {
      width: 100%;
      margin: auto; } }

#login-page .wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa; }

#login-page .login-form {
  width: 956px;
  height: 438px;
  margin: 0 auto;
  border: 1px solid #ddd;
  padding: 2rem;
  background: #ffffff; }

#login-page .form-input {
  background: #fafafa;
  border: 1px solid #eeeeee;
  padding: 12px;
  width: 100%; }

#login-page .form-group {
  margin-bottom: 1rem; }

#login-page .form-button {
  background: #69d2e7;
  border: 1px solid #ddd;
  color: #ffffff;
  padding: 10px;
  width: 100%;
  text-transform: uppercase; }

#login-page .form-button:hover {
  background: #69c8e7; }

#login-page .form-header {
  text-align: center;
  margin-bottom: 2rem; }

#login-page .form-footer {
  text-align: center; }

#create-order {
  background-color: #fff;
  padding: 1em; }
  #create-order .card-menu {
    border: 1px solid #E8EBFF; }
    #create-order .card-menu .card-body {
      padding: 0.313rem 0.625rem; }
      #create-order .card-menu .card-body .wrap-content {
        display: flex;
        flex-direction: column;
        width: 100%; }
        #create-order .card-menu .card-body .wrap-content .select-menu {
          display: flex;
          justify-content: space-between;
          padding-top: 0.313rem; }
      #create-order .card-menu .card-body .card-text {
        font-size: 16px;
        color: #004EC2;
        margin-bottom: 0; }
    #create-order .card-menu .detail-menu {
      font-size: 12px;
      color: #004ec2;
      padding: 0px 0px 5px 10px; }
  #create-order .component-tap {
    height: 88vh;
    overflow: hidden; }
  #create-order .box-number-tab-in-active {
    width: 45px;
    height: 45px;
    background-color: #E8EBFF;
    text-align: center;
    padding: 0; }
  #create-order .box-number-tab-active {
    width: 45px;
    height: 45px;
    background-color: #cdd4ff;
    text-align: center;
    padding: 0; }
  #create-order .nav-tabs {
    border-bottom: 0 !important; }
  #create-order .box-tab-content {
    position: relative;
    border: 0;
    height: 78vh; }
    #create-order .box-tab-content .inner {
      border-top-right-radius: 5px;
      border: 20px solid #cdd4ff;
      border-right: 13px solid #cdd4ff;
      border-bottom: 5px solid #cdd4ff;
      background-color: #cdd4ff;
      height: 78vh;
      overflow-y: scroll;
      overflow-x: hidden; }
      #create-order .box-tab-content .inner .tab-content {
        border: 1px solid #ffffff;
        background-color: #ffffff;
        border-radius: 5px;
        padding: 0.938rem; }
    #create-order .box-tab-content .inner::-webkit-scrollbar {
      width: 0.313rem; }
    #create-order .box-tab-content .inner::-webkit-scrollbar,
    #create-order .box-tab-content .tinner::-webkit-scrollbar-thumb {
      overflow: visible;
      border-radius: 4px; }
    #create-order .box-tab-content .inner::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.2);
      z-index: 9999999999999; }
    #create-order .box-tab-content .cover-bar {
      position: absolute;
      background: #cdd4ff;
      height: 74vh;
      top: 15px;
      right: 8px;
      width: 0.625rem;
      -webkit-transition: all 0.5s;
      opacity: 1; }
    #create-order .box-tab-content .inner:hover .cover-bar {
      opacity: 0;
      -webkit-transition: all 0.5s; }
  #create-order .font-number-tab-user {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: #004EC2; }
  #create-order .box-select-date-inactive {
    border: 1px solid #E8EBFF;
    border-radius: 5px;
    width: 362px;
    padding: 5px; }
  #create-order .box-select-date-active {
    border: 1px solid #E8EBFF;
    border-radius: 5px;
    width: 362px;
    padding: 5px;
    background-color: #004EC2; }
  #create-order .box-menu-selecct {
    position: relative;
    border: 1px solid #E8EBFF;
    width: 100%;
    border-radius: 5px;
    padding: 0.938rem;
    display: flex;
    flex-direction: column; }
    #create-order .box-menu-selecct .detail {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%; }
      #create-order .box-menu-selecct .detail .menu {
        width: 100%; }
      #create-order .box-menu-selecct .detail .item-right {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        min-width: 14rem; }
        #create-order .box-menu-selecct .detail .item-right .name {
          min-width: -webkit-max-content;
          min-width: -moz-max-content;
          min-width: max-content; }
        #create-order .box-menu-selecct .detail .item-right .products {
          min-width: -webkit-max-content;
          min-width: -moz-max-content;
          min-width: max-content;
          padding-left: 15px; }
  #create-order .font-stock-menu {
    color: #e94d4d;
    font-size: 16px; }
  #create-order .box-summary-order {
    width: 100%;
    border: 1px solid #E8EBFF;
    border-radius: 5px;
    margin-bottom: 76px; }
    #create-order .box-summary-order .box-header {
      background-color: #E8EBFF;
      border-radius: 5px 5px 0px 0px;
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center; }
    #create-order .box-summary-order .box-body {
      padding: 5px 30px 5px 30px; }
  #create-order .input-search {
    background: url(/static/media/Search.efe6aea7.svg) no-repeat scroll 7px 7px;
    padding-left: 40px;
    background-size: 20px;
    border-radius: 5px; }
  #create-order .box-button-checkout {
    background-color: #cdd4ff;
    padding: 10px 20px 20px 20px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute;
    bottom: 0;
    z-index: 99;
    width: 100%;
    left: 0;
    display: flex; }
  #create-order .box-caed-empty {
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center; }
    #create-order .box-caed-empty span {
      font-size: 50px;
      line-height: 65px;
      color: #E8EBFF; }
  #create-order .icon-delete-product {
    position: absolute;
    top: -12px;
    right: -9px; }
  #create-order .box-discount {
    background-color: #E8EBFF;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    margin-top: 14px;
    position: relative; }
    #create-order .box-discount .icon-status-code {
      position: absolute;
      right: 23px;
      top: 42px; }
  #create-order .disable-card-product {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    z-index: 99;
    width: 100%;
    height: 100%; }
  #create-order .close-icon-search {
    width: 17px;
    height: 18px; }
  #create-order .close-icon-search:hover {
    width: 20px;
    height: 20px; }
  #create-order .search-inside-input {
    position: absolute;
    top: 5px;
    right: 10px;
    z-index: 50; }

.bin-icon {
  height: 20px;
  width: 20px; }

.scroll-date-view {
  background-color: white;
  width: 29rem;
  height: 9rem;
  overflow: auto; }

#table-component .thead-custom {
  background-color: #cdd4ff;
  border: 3px solid #cdd4ff; }

#table-component .tr-odd {
  background-color: #FFFFFF; }

#table-component .tr-even {
  background-color: #F0F1F9;
  border: 3px solid #F0F1F9; }

.logo {
  width: 74px;
  height: 45px;
  margin-left: 2rem; }

.nav-bar {
  font-size: 20px;
  margin-left: 1rem;
  color: black; }

.mt-2rem {
  margin-right: 2rem; }

.nav-menu {
  height: 4rem;
  width: 9rem;
  display: flex;
  justify-content: center;
  padding-top: 1rem; }

.nav-menu:hover {
  height: 4rem;
  width: 9rem;
  display: flex;
  background-color: #e8ebff;
  justify-content: center;
  padding-top: 1rem; }

.nav-menu-active {
  height: 4rem;
  width: 9rem;
  display: flex;
  background-color: #e8ebff;
  justify-content: center;
  padding-top: 1rem; }

.padding-0 {
  padding: 0px; }

.list-menu-text {
  font-size: 16px;
  line-height: 21px;
  color: #333333; }

.header-active {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: #004EC2; }

.header-inactive {
  font-weight: bold;
  font-size: 16px;
  line-height: 21px; }

.name-of-side {
  margin-top: 25px !important; }

.w-h-30px {
  width: 30px;
  height: 30px; }

.side-item {
  margin-top: 15px; }

.list-menu:hover {
  background-color: #e8ebff; }

.list-menu-active {
  background-color: #e8ebff;
  color: #004EC2; }
  .list-menu-active a {
    color: #004EC2; }

.list-menu {
  font-size: 16px;
  line-height: 21px; }
  .list-menu a {
    color: #333333; }

.title-sidebar-menu {
  font-weight: bold;
  font-size: 32px;
  line-height: 32px; }

.display-block {
  display: block;
  padding: .5rem 1rem; }

.sidebar-extra-data {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .sidebar-extra-data span {
    padding: 1em 3em;
    width: 100%;
    text-align: center;
    display: block; }

.side-item:hover {
  background-color: #313134;
  color: #fff; }

.side-item-active {
  background-color: #313134;
  color: #fff; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  font-family: "Sarabun", sans-serif !important;
  background-color: #F6F6F6; }

.btn.focus, .btn:focus {
  box-shadow: none !important; }

.div-chat {
  display: flex;
  flex-direction: column;
  height: 86vh;
  justify-content: space-between; }

.wrap-div-chat {
  height: 86vh;
  display: block; }

.div-list-data {
  height: 69vh;
  position: relative; }

.announce {
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 15px; }
  .announce .text-announce {
    color: #004EC2;
    background-color: #F6F6F6;
    width: 100%;
    padding: 0.5rem;
    border-radius: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0; }
    .announce .text-announce .haeder {
      font-weight: bold;
      padding-right: 0.5rem; }

.chat-box {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0 0 6px 6px;
  z-index: 999; }
  .chat-box .input-group {
    flex-direction: column; }
    .chat-box .input-group .form-control {
      border-radius: 10px;
      border: 1px solid #F6F6F6;
      background-color: #F6F6F6;
      margin-right: 1rem !important;
      font-size: 14px;
      font-weight: bold;
      width: 100%; }

.btn-attach {
  background-color: #ffffff !important;
  color: #004EC2 !important;
  border-color: #ffffff !important;
  font-size: 1.75rem !important;
  line-height: 1rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  position: relative;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .btn-attach .imgcloud {
    margin-bottom: 5px;
    width: 36px;
    height: 25px; }

.attach-img {
  position: relative;
  display: flex;
  align-items: center;
  background-color: red;
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  margin-right: 0.5rem;
  -webkit-transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
  .attach-img .img-upload {
    height: 30px;
    width: 30px;
    background-color: red; }
    .attach-img .img-upload img {
      height: 30px;
      width: 30px;
      object-fit: cover;
      object-position: center; }
  .attach-img .box-file-name {
    position: relative; }
  .attach-img label {
    padding-left: 0.5rem;
    margin-bottom: 0;
    font-size: 0.85rem;
    color: #131414;
    max-width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .attach-img .btn-close {
    position: absolute;
    top: -10px;
    right: -8px;
    color: #EB5757;
    padding: .375rem .5rem; }
    .attach-img .btn-close:focus {
      box-shadow: none !important;
      outline: none !important; }

input[type=file] {
  outline: 0;
  opacity: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%; }

.div-msg {
  display: flex;
  border: 1px solid gray;
  width: 90%;
  height: 700px;
  margin: 0.5rem; }

.div-msg-customer {
  max-width: 45%;
  display: flex;
  align-items: flex-start;
  margin: 1rem 0;
  justify-content: flex-start; }
  @media (max-width: 768px) {
    .div-msg-customer {
      max-width: 80%; } }

.div-msg-call-center {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0; }
  .div-msg-call-center span {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 0.5rem;
    font-size: 0.65rem;
    color: gray; }
  .div-msg-call-center img {
    width: 100%;
    cursor: pointer; }

.box-msg-customer {
  display: flex;
  flex-direction: row;
  align-items: flex-end; }
  .box-msg-customer .w-sticker {
    width: 8rem;
    border-color: #ffffff;
    background-color: #ffffff; }
  .box-msg-customer img {
    width: 100%;
    cursor: pointer; }
  .box-msg-customer label {
    border: 1px solid #F6F6F6;
    background-color: #F6F6F6;
    border-radius: 10px;
    padding: .375rem .375rem;
    margin-bottom: 0;
    word-break: break-word; }
  .box-msg-customer div {
    border: 1px solid #F6F6F6;
    background-color: #F6F6F6;
    border-radius: 10px;
    padding: .375rem .375rem;
    margin-bottom: 0;
    max-width: 15rem;
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    display: inline-block;
    word-break: break-word; }
  .box-msg-customer span {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 0.65rem;
    color: gray;
    min-width: 86px; }
  .box-msg-customer img {
    border: 1px solid #F6F6F6;
    background-color: #F6F6F6;
    border-radius: 10px;
    padding: .375rem .75rem;
    margin-bottom: 0;
    width: 100%; }

.box-img-customer {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  margin-right: 10px; }
  .box-img-customer img {
    height: 40px;
    width: 40px;
    border-radius: 20px; }

.box-img-call-center {
  border: 1px solid red;
  height: 40px;
  width: 40px;
  border-radius: 10px; }

.wrap-time {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-width: 86px;
  padding-bottom: 0.5rem; }

.box-msg-call-center {
  border: 1px solid #004EC2;
  background-color: #004EC2;
  color: #ffffff;
  max-width: 45%;
  border-radius: 10px;
  padding: .375rem .375rem;
  word-break: break-word; }
  @media (max-width: 768px) {
    .box-msg-call-center {
      max-width: 80%; } }
  .box-msg-call-center img {
    width: 100%;
    padding: 0.25rem;
    border-radius: 10px; }

.wd-he-toscroll {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1rem;
  padding-bottom: 165px; }

.btn-close-note {
  position: absolute;
  right: 0rem;
  top: 0rem;
  z-index: 1051; }
  .btn-close-note i {
    font-size: 1.25rem;
    color: #ffffff; }
  .btn-close-note:focus {
    outline: none !important;
    box-shadow: none !important; }
  .btn-close-note:hover {
    color: rgba(255, 255, 255, 0.75) !important; }

.modal-footer-note {
  border-top: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem; }

.pd-t-2 {
  padding-top: 2rem; }

.bg-modal-header {
  background-color: #004EC2;
  color: #ffffffff !important; }

.modal-content {
  border: 0 !important; }

.container-map {
  display: block;
  width: 100%;
  background-color: #ffffff;
  padding: 15px 0; }

.div-map {
  width: 100%;
  height: 200px;
  position: relative;
  display: block; }

.line-haeder {
  border-bottom: 1px solid #EFF0F6;
  margin: 0 1rem; }

.customer-haeder {
  padding: 1rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 5px; }
  @media (max-width: 768px) {
    .customer-haeder {
      flex-direction: column;
      align-items: flex-start; } }
  .customer-haeder .haeder-right .btn-search {
    color: #004EC2;
    margin-right: 0.5rem;
    font-size: 1.5rem; }
  .customer-haeder .profile {
    display: flex;
    flex-direction: row;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    align-items: center; }
    @media (max-width: 768px) {
      .customer-haeder .profile {
        padding-bottom: 10px; } }
    .customer-haeder .profile .box-img {
      height: 50px;
      width: 50px;
      border-radius: 10px;
      margin-right: 10px; }
      .customer-haeder .profile .box-img img {
        height: 50px;
        width: 50px;
        border-radius: 10px; }
    .customer-haeder .profile label {
      margin-bottom: 0;
      color: #131414;
      font-weight: bold;
      padding-left: 0.35rem; }
  .customer-haeder .status-customer {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .customer-haeder .status-customer label {
      margin-bottom: 0;
      padding-left: 10px; }
      .customer-haeder .status-customer label.status {
        color: #004EC2; }

.status-box {
  display: flex;
  flex-direction: column; }
  .status-box .input-group > .custom-select:not(:last-child), .status-box .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important; }
  .status-box .social {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 0.5rem; }
    .status-box .social .line {
      background-color: #62BA44;
      color: #ffffff;
      width: 1.15rem;
      height: 1.15rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 0.75rem; }
    .status-box .social .facebook {
      background-color: #2E5186;
      color: #ffffff;
      width: 1.15rem;
      height: 1.15rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 0.75rem; }
    .status-box .social .phone {
      background-color: #ED6529;
      color: #ffffff;
      width: 1.15rem;
      height: 1.15rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 0.75rem; }

.btn-edit {
  border-color: #004EC2 !important;
  background-color: #ffffff !important;
  color: #004EC2 !important;
  font-size: 0.75rem !important;
  padding: 0rem 0.45rem !important;
  border-radius: 0.25rem !important; }
  .btn-edit.edit {
    border-color: #ffffff !important; }
  .btn-edit:focus {
    box-shadow: none !important; }

.div-btn-pick-color-modal-tag {
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .div-btn-pick-color-modal-tag .box-select {
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .div-btn-pick-color-modal-tag .box-select span {
      font-size: 1rem;
      color: #131414; }
  .div-btn-pick-color-modal-tag .box-button {
    display: flex;
    justify-content: flex-start;
    align-items: center; }

.btn-color-picker {
  margin-left: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border: 3px solid #ffffff;
  border-radius: 50%;
  padding: 0; }
  .btn-color-picker:focus {
    border: 3px solid #E0E0E0;
    box-shadow: none !important;
    outline: none !important; }

.btn-tags {
  color: #ffffff !important;
  border: 3px solid transparent;
  border-color: #ffffff;
  margin-right: 0.25rem;
  border-radius: 10px !important;
  padding: 0.2rem 0.5rem !important; }
  .btn-tags:focus {
    border: 3px solid #E0E0E0;
    box-shadow: none !important;
    outline: none !important; }

.wrap-add-tag {
  padding: 1rem 0; }
  .wrap-add-tag .input-group {
    position: relative; }
    .wrap-add-tag .input-group .input-group-prepend {
      position: absolute;
      right: 1rem;
      top: 1.4rem;
      z-index: 99; }
      .wrap-add-tag .input-group .input-group-prepend label {
        margin-bottom: 0;
        color: #828282; }
  .wrap-add-tag input {
    margin-top: 1rem;
    padding-right: 4.5rem; }

.ling-tag {
  border-bottom: 1px solid #EFF0F6; }

.wrap-btn-file-chat {
  display: block;
  padding-left: 50px;
  padding-bottom: 1rem; }

.btn-download {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #004EC2;
  border-right-color: #131414;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem; }
  .btn-download.border-none {
    border-right-color: transparent; }

.btn-upload {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #004EC2;
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem; }

.btn-img-upload:disabled {
  color: #62BA44 !important;
  opacity: 1 !important; }

.copy-clipboard {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content; }

body {
  font-family: "Sarabun", sans-serif !important;
  background-color: #F6F6F6; }

.btn.focus, .btn:focus {
  box-shadow: none !important; }

@media (min-width: 576px) {
  .modal-dialog.modal-medium {
    max-width: 35rem !important; } }

.modal-medium .modal-content {
  border-radius: 5px; }

.modal-medium .modal-header {
  border-bottom: 0 !important;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 1.5rem 2rem 1rem 2rem;
  position: relative; }
  .modal-medium .modal-header h3 {
    margin-bottom: 0;
    font-size: 1.25rem;
    color: #131414;
    font-weight: bold; }
  .modal-medium .modal-header .btn-cloes {
    position: absolute;
    right: 1rem;
    top: 0.25rem;
    padding: 0; }
    .modal-medium .modal-header .btn-cloes i {
      font-size: 1.5rem;
      color: #131414; }

.modal-medium .modal-body {
  padding: 1rem 2rem; }
  .modal-medium .modal-body .invalid-text {
    display: flex;
    width: 100%;
    justify-content: center;
    color: #E94D4D;
    padding-top: 1rem; }
  .modal-medium .modal-body .center {
    display: flex;
    width: 100%;
    justify-content: center; }
  .modal-medium .modal-body button.btn-map {
    width: 80%; }
  .modal-medium .modal-body.preview {
    padding: 1rem; }
  .modal-medium .modal-body .box-img {
    width: 100%; }
    .modal-medium .modal-body .box-img img {
      width: 100%; }
    .modal-medium .modal-body .box-img .btn-cloes {
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
      padding: 0;
      background-color: #ffffff;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0; }
      .modal-medium .modal-body .box-img .btn-cloes i {
        font-size: 1.5rem;
        color: #131414; }
  .modal-medium .modal-body .text-haeder {
    font-size: 1.2rem;
    color: #131414;
    margin-bottom: 0; }
  .modal-medium .modal-body .form-control {
    border-radius: 5px;
    background-color: #F6F6F6; }
  .modal-medium .modal-body .valid {
    border: 1px solid #F6F6F6 !important; }
  .modal-medium .modal-body .invalid {
    border: 1px solid #E94D4D !important; }
  .modal-medium .modal-body .form-group label {
    color: #131414;
    font-size: 1rem; }
    .modal-medium .modal-body .form-group label span {
      color: #eb5757; }
  .modal-medium .modal-body .form-group .form-control {
    border-radius: 5px;
    border: 1px solid #F6F6F6; }

.modal-medium .modal-footer {
  justify-content: center;
  border-top: 0 !important;
  padding: 0 2rem 2rem 2rem; }
  .modal-medium .modal-footer button {
    width: 80%; }

@media (min-width: 576px) {
  .modal-dialog.modal-large {
    max-width: 65rem !important; } }

.modal-large .tite-text {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0; }

.modal-large .topic-text {
  font-weight: bold;
  font-size: 14px;
  color: #131414;
  margin-bottom: 0; }

.modal-large .text-status {
  font-size: 14px;
  color: #131414;
  margin-bottom: 0; }
  .modal-large .text-status i.confirm {
    color: #62ba44;
    font-size: 1rem;
    margin-right: 5px; }
  .modal-large .text-status i.wait {
    color: #f2c94c;
    font-size: 1rem;
    margin-right: 5px; }
  .modal-large .text-status i.expire {
    color: #e94d4d;
    font-size: 1rem;
    margin-right: 5px; }

.modal-large .box-sum {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 15px 0 15px;
  margin-left: 30px; }
  .modal-large .box-sum .text-sum {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0; }

.modal-large .box-order {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 15px 30px;
  margin-left: 30px;
  border-bottom: 1px solid #e0e0e0; }
  .modal-large .box-order .row-order {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .modal-large .box-order .topic-order {
    display: flex;
    flex-direction: column; }

.modal-large .box-order-wait {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 15px 15px;
  margin-left: 15px;
  border-bottom: 1px solid #e0e0e0; }
  .modal-large .box-order-wait .row-order {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
    .modal-large .box-order-wait .row-order .form-group {
      padding: 0;
      width: 100%;
      margin-bottom: 0; }
  .modal-large .box-order-wait .topic-order {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 30px; }
    .modal-large .box-order-wait .topic-order .row-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      line-height: 14px; }

.modal-large .text-order {
  font-size: 16px;
  margin-bottom: 0; }

.modal-large ._blacl {
  color: #004EC2; }

.modal-large ._blue {
  color: #004EC2; }

.modal-large ._normal {
  font-weight: normal !important; }

.modal-large ._line-height-14 {
  line-height: 14px; }

.modal-large .wrap-topic {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 30px; }

.modal-large .wrap-invoice {
  height: 63vh;
  overflow-y: scroll;
  background: #F6F6F6;
  border-radius: 5px;
  border: 1px solid #F6F6F6;
  padding: 15px;
  margin-bottom: 15px; }
  .modal-large .wrap-invoice .form-group {
    position: relative;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
    .modal-large .wrap-invoice .form-group.confirm {
      border-left: 5px solid #62ba44; }
    .modal-large .wrap-invoice .form-group.wait {
      border-left: 5px solid #f2c94c; }
    .modal-large .wrap-invoice .form-group.expire {
      border-left: 5px solid #e94d4d; }
  .modal-large .wrap-invoice .form-check-input {
    width: 1.5rem;
    height: 1.5rem;
    left: 30px;
    z-index: 99999; }

.modal-large .form-control {
  background-color: #F6F6F6;
  border-color: #F6F6F6; }

.modal-large .modal-content {
  border-radius: 5px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center; }

.modal-large .modal-header {
  border-bottom: 0 !important;
  text-align: center;
  display: flex;
  justify-content: center;
  padding: 1.5rem 2rem 1rem 2rem;
  position: relative; }
  .modal-large .modal-header .btn-cloes {
    position: absolute;
    right: 1rem;
    top: 0.25rem;
    padding: 0; }
    .modal-large .modal-header .btn-cloes i {
      font-size: 1.5rem;
      color: #131414; }

.modal-large .modal-body.msg {
  width: 350px;
  display: flex;
  flex-direction: column; }
  .modal-large .modal-body.msg label.icon {
    margin-bottom: 0;
    color: #004EC2;
    font-size: 7rem;
    line-height: 5rem;
    text-align: center;
    width: 100%;
    padding-bottom: 20px; }
  .modal-large .modal-body.msg label.message {
    margin-bottom: 20px;
    color: #131414;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    white-space: pre-wrap;
    font-weight: bold; }
  .modal-large .modal-body.msg button {
    width: 100%;
    margin-bottom: 20px; }
    .modal-large .modal-body.msg button:last-child {
      margin-bottom: 0; }

.modal-large .modal-body {
  padding: 2rem;
  padding-top: 2.5rem;
  background-color: #ffffff;
  border-radius: 5px; }
  .modal-large .modal-body .div-order {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    position: relative; }
  .modal-large .modal-body .box-preview {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 9999999999;
    height: 80%; }
    .modal-large .modal-body .box-preview img {
      height: 100%; }
  .modal-large .modal-body .div-upload-slip {
    display: flex;
    flex-direction: column;
    padding-right: 2rem;
    min-width: 40%;
    max-width: 40%;
    border-right: 1px solid #E0E0E0; }
    .modal-large .modal-body .div-upload-slip .box-img {
      width: 15rem;
      height: 15rem;
      position: relative; }
      .modal-large .modal-body .div-upload-slip .box-img .img-slip {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; }
      .modal-large .modal-body .div-upload-slip .box-img .btn-close {
        position: absolute;
        top: -0.5rem;
        right: -0.5rem;
        background-color: #E0E0E0;
        border-radius: 50%;
        padding: 0;
        width: 1.5rem;
        height: 1.5rem;
        color: #131414; }
    .modal-large .modal-body .div-upload-slip h3 {
      margin-bottom: 1rem;
      font-size: 1.25rem;
      color: #131414;
      font-weight: bold; }
    .modal-large .modal-body .div-upload-slip .btn-attach-modal {
      background-color: #e8ebff !important;
      color: #004EC2 !important;
      border-color: #e8ebff !important;
      width: 15rem;
      height: 15rem;
      position: relative; }
      .modal-large .modal-body .div-upload-slip .btn-attach-modal i {
        font-size: 3rem;
        line-height: 2.5rem; }
        .modal-large .modal-body .div-upload-slip .btn-attach-modal i::before {
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg); }
      .modal-large .modal-body .div-upload-slip .btn-attach-modal input[type="file"] {
        width: 100%; }
    .modal-large .modal-body .div-upload-slip .wrap-form {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-top: 1rem; }
  .modal-large .modal-body .box-invoice {
    display: flex;
    flex-direction: column;
    min-width: 60%;
    max-width: 60%;
    padding-left: 2rem;
    position: relative; }
    .modal-large .modal-body .box-invoice button.btn-cloes {
      position: absolute;
      top: -35px;
      right: -15px;
      z-index: 999999;
      font-size: 2rem;
      line-height: 2rem;
      padding: 0; }
    .modal-large .modal-body .box-invoice .header-invoice {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .modal-large .modal-body .box-invoice .header-invoice h3 {
        margin-bottom: 1rem;
        font-size: 1.25rem;
        color: #131414;
        font-weight: bold; }

.modal-large .modal-footer {
  justify-content: center;
  border-top: 0 !important;
  padding: 0 2rem 2rem 2rem; }
  .modal-large .modal-footer button {
    width: 80%; }

.color-red {
  color: red; }

.delete-invoice {
  width: 100px;
  margin-left: auto; }

.btn-blue-white {
  background-color: #e8ebff; }

.modal-select {
  max-width: 90%; }

.modal-editname {
  text-align: center;
  top: 25%; }
  .modal-editname .btn {
    padding: 0 0.75rem; }
    .modal-editname .btn .btn-cloes {
      float: right;
      font-size: 1.5rem; }
  .modal-editname .box-modaleditname {
    margin: 2.5rem 4rem; }
    .modal-editname .box-modaleditname .editname {
      margin-top: 2.75rem; }
      .modal-editname .box-modaleditname .editname .input-editname {
        height: 2.5rem;
        margin-bottom: 1rem;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 5px; }
      .modal-editname .box-modaleditname .editname .btn-save-editname {
        padding: .5rem 0;
        background: #004EC2;
        border: 1px solid #004EC2;
        border-radius: 5px;
        color: #FFFFFF; }
    .modal-editname .box-modaleditname p {
      padding-top: 1.25rem;
      text-align: center;
      color: #E94D4D; }

.modal-movealbum {
  text-align: center;
  top: 25%; }
  .modal-movealbum .btn {
    padding: 0 0.75rem; }
    .modal-movealbum .btn .btn-cloes {
      float: right;
      font-size: 1.5rem; }
  .modal-movealbum .box-movealbum {
    margin: 2.5rem 4rem; }
    .modal-movealbum .box-movealbum .movealbum {
      margin-top: 2.75rem; }
      .modal-movealbum .box-movealbum .movealbum .seletealbum {
        padding: 0;
        margin-bottom: 1rem; }
      .modal-movealbum .box-movealbum .movealbum .btn-confirmmove {
        padding: .5rem 0;
        background: #004EC2;
        border: 1px solid #004EC2;
        border-radius: 5px;
        color: #FFFFFF; }

.modal-delete {
  text-align: center;
  top: 25%; }
  .modal-delete .box-modaldelete {
    margin: 3.3rem 4rem; }
    .modal-delete .box-modaldelete .confirm {
      margin: 2.75rem 0 3.25rem 0; }
      .modal-delete .box-modaldelete .confirm .btn-accept {
        margin: 1rem 0;
        padding: .5rem 0;
        background: #E94D4D;
        border: 1px solid #E94D4D;
        border-radius: 5px;
        color: #FFFFFF; }
      .modal-delete .box-modaldelete .confirm .btn-reject {
        padding: .5rem 0;
        background: #FFFFFF;
        border: 1px solid #E0E0E0;
        border-radius: 5px; }

.modal-create {
  text-align: center;
  top: 3.5%;
  width: 90% !important;
  max-width: 90% !important; }
  .modal-create .box-modalcreate .tite-text {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 3rem; }
  .modal-create .box-modalcreate .btn {
    padding: 0 0.75rem; }
    .modal-create .box-modalcreate .btn .btn-cloes {
      float: right;
      font-size: 1.5rem; }
  .modal-create .box-modalcreate .box-img {
    padding: 0 4rem; }
    .modal-create .box-modalcreate .box-img .div-upload {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-template-rows: repeat(4, 20vh); }
      .modal-create .box-modalcreate .box-img .div-upload .btn-attach-modal {
        border-radius: 0;
        border: solid 1px #E0E0E0;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        padding: 0.8rem;
        width: 100%;
        height: 100%; }
        .modal-create .box-modalcreate .box-img .div-upload .btn-attach-modal p {
          margin-top: 0.5rem;
          font-size: 1.2rem; }
        .modal-create .box-modalcreate .box-img .div-upload .btn-attach-modal i {
          font-size: 5rem;
          color: #004EC2;
          line-height: 0.1rem;
          padding-bottom: 0px; }
        .modal-create .box-modalcreate .box-img .div-upload .btn-attach-modal .input-img {
          top: 13px;
          left: 77px;
          width: 190px;
          height: 170px; }
          .modal-create .box-modalcreate .box-img .div-upload .btn-attach-modal .input-img input[type=file] {
            top: 13px;
            left: 77px;
            width: 190px;
            height: 170px; }
      .modal-create .box-modalcreate .box-img .div-upload .warp-img {
        display: flex;
        padding: 0.8rem; }
        .modal-create .box-modalcreate .box-img .div-upload .warp-img img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          border: solid 1px #E0E0E0;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2); }
      .modal-create .box-modalcreate .box-img .div-upload .img-thumbnail {
        flex: 1 1;
        background-color: #ffffff;
        border: solid 1px #E0E0E0;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        height: 8rem;
        width: 100rem;
        width: 100%;
        height: 100%;
        margin: 1.125rem 0; }
      .modal-create .box-modalcreate .box-img .div-upload .box-close {
        margin: 1.25rem 1 0 0;
        position: relative; }
        .modal-create .box-modalcreate .box-img .div-upload .box-close .btn-close {
          font-size: 1.5rem;
          color: rgba(0, 0, 0, 0.7);
          top: -28px;
          right: -25px;
          position: absolute;
          z-index: 9999; }
  .modal-create .box-modalcreate .box-text {
    padding: 0 4rem;
    border-left: 1px solid #E0E0E0; }
    .modal-create .box-modalcreate .box-text .title-addimage {
      text-align: start; }
    .modal-create .box-modalcreate .box-text .selete-album {
      text-align: start;
      padding: 1.25rem 0; }
  .modal-create .btn-comfirm {
    float: right;
    padding: 0.5rem 2rem;
    margin: 1rem 4rem; }

.modal-select .btn {
  padding: 0 0.75rem; }
  .modal-select .btn .btn-cloes {
    float: right;
    font-size: 1.5rem; }

.modal-select .title-albumDetail {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem; }

.modal-select .back-btn {
  margin: 1rem 0; }

.modal-select .filter-tab {
  padding: 0;
  margin: 0; }

.modal-select .checkbox-media-all {
  padding: 0; }
  .modal-select .checkbox-media-all input {
    width: 1.125rem;
    height: 1.125rem; }

.modal-select .row {
  padding: 0.5rem 1rem 0 1rem; }
  .modal-select .row i {
    color: #E0E0E0;
    text-align: center;
    align-self: center; }

.modal-select .div-recent {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(7, 1fr); }
  .modal-select .div-recent .box-recent {
    position: relative;
    height: 12rem;
    margin: 0.5rem 0;
    border: solid 1px #E0E0E0;
    padding-bottom: 10px; }
    .modal-select .div-recent .box-recent .name-media {
      white-space: nowrap;
      min-width: 100px !important;
      max-width: 150px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0.5rem; }
    .modal-select .div-recent .box-recent .img-album {
      object-fit: cover;
      height: 75%;
      width: 100%; }
    .modal-select .div-recent .box-recent input {
      display: block;
      position: absolute;
      margin-top: -80px; }
    .modal-select .div-recent .box-recent .select-media {
      top: 0.25rem;
      left: 0.25rem;
      position: absolute; }
      .modal-select .div-recent .box-recent .select-media .index-select {
        background: #131414;
        opacity: 0.7;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        text-align: center;
        color: #ffffff; }
    .modal-select .div-recent .box-recent .checkbox-media {
      top: 5.4rem;
      right: 1.3rem;
      position: absolute; }
      .modal-select .div-recent .box-recent .checkbox-media input {
        width: 1rem;
        height: 1rem; }
  @media (max-width: 575.98px) {
    .modal-select .div-recent {
      grid-template-columns: repeat(1, 1fr); }
      .modal-select .div-recent .box-recent {
        height: 12rem;
        position: relative;
        justify-self: center; }
        .modal-select .div-recent .box-recent .checkbox-media {
          top: 5.3rem;
          right: 1.3rem;
          position: absolute; }
          .modal-select .div-recent .box-recent .checkbox-media input {
            width: 1rem;
            height: 1rem; } }
  @media (max-width: 768.98px) and (min-width: 576px) {
    .modal-select .div-recent {
      grid-template-columns: repeat(2, 1fr); } }
  @media (max-width: 991.98px) and (min-width: 768px) {
    .modal-select .div-recent {
      grid-template-columns: repeat(3, 1fr); } }
  @media (max-width: 1199.98px) and (min-width: 992px) {
    .modal-select .div-recent {
      grid-template-columns: repeat(4, 1fr); } }
  @media (min-width: 1200px) {
    .modal-select .div-recent {
      grid-template-columns: repeat(5, 1fr); } }
  @media (min-width: 1300px) {
    .modal-select .div-recent {
      grid-template-columns: repeat(6, 1fr); } }
  @media (min-width: 1400px) {
    .modal-select .div-recent {
      grid-template-columns: repeat(7, 1fr); } }

.modal-select .album-all {
  max-height: 440px;
  overflow: scroll; }
  .modal-select .album-all .div-album {
    display: grid;
    grid-gap: 0px;
    margin-bottom: 0; }
    @media (max-width: 575.98px) {
      .modal-select .album-all .div-album {
        grid-template-columns: repeat(1, 1fr); } }
    @media (max-width: 768.98px) and (min-width: 576px) {
      .modal-select .album-all .div-album {
        grid-template-columns: repeat(2, 1fr); } }
    @media (max-width: 991.98px) and (min-width: 768px) {
      .modal-select .album-all .div-album {
        grid-template-columns: repeat(3, 1fr); } }
    @media (max-width: 1199.98px) and (min-width: 992px) {
      .modal-select .album-all .div-album {
        grid-template-columns: repeat(4, 1fr); } }
    @media (min-width: 1200px) {
      .modal-select .album-all .div-album {
        grid-template-columns: repeat(5, 1fr); } }
    @media (min-width: 1300px) {
      .modal-select .album-all .div-album {
        grid-template-columns: repeat(6, 1fr); } }
    .modal-select .album-all .div-album .box-album-relative,
    .modal-select .album-all .div-album .box-album {
      height: 11rem;
      width: 11rem; }
      @media (max-width: 575.98px) {
        .modal-select .album-all .div-album .box-album-relative,
        .modal-select .album-all .div-album .box-album {
          height: 10rem;
          width: 10rem; } }
      @media (min-width: 1200px) {
        .modal-select .album-all .div-album .box-album-relative,
        .modal-select .album-all .div-album .box-album {
          height: 10rem;
          width: 10rem; } }
      @media (min-width: 1600px) {
        .modal-select .album-all .div-album .box-album-relative,
        .modal-select .album-all .div-album .box-album {
          height: 14rem;
          width: 14rem; } }

.modal-select .btn-comfirm {
  margin-top: 2rem;
  float: right;
  padding: 0.5rem 2rem; }

.cur-point {
  cursor: pointer; }

body {
  font-family: "Sarabun", sans-serif !important;
  background-color: #F6F6F6; }

.btn.focus, .btn:focus {
  box-shadow: none !important; }

.div-side-bar-close {
  height: 87vh;
  position: relative;
  margin-left: 15px;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  border-radius: 5px; }
  .div-side-bar-close .btn-close {
    position: relative;
    top: 0;
    right: 0;
    background-color: #ffffff;
    border: 1px solid #E0E0E0; }
    .div-side-bar-close .btn-close i {
      font-size: 1.75rem; }
    .div-side-bar-close .btn-close:focus {
      outline: none !important;
      box-shadow: none !important; }

.div-side-bar-open {
  height: 87vh;
  border: 1px solid #E0E0E0;
  position: relative;
  margin-left: 15px;
  background-color: #ffffff;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  border-radius: 5px;
  min-width: 23rem;
  max-width: 23rem; }
  .div-side-bar-open .btn-open {
    position: absolute;
    background-color: white;
    top: 0;
    right: 0; }
    .div-side-bar-open .btn-open i {
      font-size: 1.75rem; }
    .div-side-bar-open .btn-open i::before {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }
    .div-side-bar-open .btn-open:focus {
      outline: none !important;
      box-shadow: none !important; }

.wrap-side-bar::-webkit-scrollbar {
  width: .4em; }

.wrap-side-bar::-webkit-scrollbar,
.wrap-side-bar::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

.wrap-side-bar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); }

.cover-bar {
  position: absolute;
  background: #fff;
  height: 100%;
  top: 0;
  right: 0;
  width: .4em;
  border-radius: 5px !important;
  -webkit-transition: all .5s;
  opacity: 1; }

.wrap-side-bar:hover .cover-bar {
  opacity: 0;
  -webkit-transition: all .5s; }

.wrap-side-bar {
  background-color: #ffffff;
  width: 100%;
  height: 86.6vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 2rem 1.5rem;
  border-radius: 5px; }
  .wrap-side-bar .wrap-header {
    padding: 1rem 0;
    display: flex;
    flex-direction: column; }
    .wrap-side-bar .wrap-header .wrap-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-bottom: 1px solid #E0E0E0; }
    .wrap-side-bar .wrap-header a {
      cursor: pointer; }
      .wrap-side-bar .wrap-header a.btn-address {
        color: #004EC2;
        font-size: 1.1rem;
        padding-bottom: 1rem; }
    .wrap-side-bar .wrap-header .row-center {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 0.5rem; }
    .wrap-side-bar .wrap-header .box-avatar {
      width: 9rem;
      height: 9rem;
      border-radius: 50%; }
      .wrap-side-bar .wrap-header .box-avatar img {
        width: 9rem;
        height: 9rem;
        border-radius: 50%; }
    .wrap-side-bar .wrap-header .name {
      font-size: 1.25rem;
      color: #131414;
      font-weight: bold;
      text-align: center; }
    .wrap-side-bar .wrap-header .view-history {
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
    .wrap-side-bar .wrap-header .wrap-info {
      display: flex;
      flex-direction: column; }
      .wrap-side-bar .wrap-header .wrap-info .haeder {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 1rem; }
        .wrap-side-bar .wrap-header .wrap-info .haeder .info-haeder {
          font-size: 1.1rem;
          color: #131414;
          margin-bottom: 0; }
        .wrap-side-bar .wrap-header .wrap-info .haeder button {
          padding: 0 !important; }
          .wrap-side-bar .wrap-header .wrap-info .haeder button i {
            color: #004EC2;
            font-size: 1.75rem; }
      .wrap-side-bar .wrap-header .wrap-info .info {
        font-size: 1.1rem;
        color: #131414;
        margin-bottom: 0.75rem;
        font-weight: bold; }
  .wrap-side-bar .wrap-setting {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E0E0E0;
    padding: 1rem 0; }
    .wrap-side-bar .wrap-setting .row-setting {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .wrap-side-bar .wrap-setting .row-setting .text-setting {
        font-size: 1.1rem;
        color: #131414;
        margin-bottom: 0; }
    .wrap-side-bar .wrap-setting .form-group {
      margin-top: 1rem;
      margin-bottom: 0; }
      .wrap-side-bar .wrap-setting .form-group .form-control {
        background-color: #F6F6F6;
        border-color: #F6F6F6; }

body {
  font-family: "Sarabun", sans-serif !important;
  background-color: #F6F6F6; }

.btn.focus, .btn:focus {
  box-shadow: none !important; }

.haeder-user-chat {
  z-index: 99;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 0;
  background-color: #ffffff;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important; }
  .haeder-user-chat h2 {
    color: #131414;
    font-size: 1.75rem;
    margin: 0 !important;
    width: 100%; }

.wrap-box-user {
  display: flex;
  flex-direction: column;
  padding-top: 145px;
  z-index: 5; }

.box-customer {
  border: 1px solid #F6F6F6;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between; }
  .box-customer.active-box {
    background-color: #E0E0E0; }
  .box-customer .box-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
    position: relative; }
    .box-customer .box-img img {
      height: 40px;
      width: 40px;
      border-radius: 50%; }
    .box-customer .box-img .social {
      position: absolute;
      bottom: -1px;
      right: -3px;
      width: 1.15rem;
      height: 1.15rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 0.75rem;
      z-index: 999; }
      .box-customer .box-img .social.facebook {
        background-color: #2E5186; }
      .box-customer .box-img .social.line {
        background-color: #62BA44; }
      .box-customer .box-img .social.phone {
        background-color: #ED6529; }
      .box-customer .box-img .social i::before {
        font-size: 0.75rem;
        color: #ffffff; }
  .box-customer .detail {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding-right: 10px; }
    .box-customer .detail label {
      margin-bottom: 0 !important;
      font-size: 0.85rem;
      font-weight: bold;
      color: #131414;
      max-width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .box-customer .detail label.msg {
        color: #131414;
        font-weight: normal; }
    @media (max-width: 768px) {
      .box-customer .detail {
        width: 78%; } }

.box-customer-noti {
  border: 1px solid #F6F6F6;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  justify-content: space-between; }
  .box-customer-noti.active-box {
    background-color: #E0E0E0; }
  .box-customer-noti .box-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
    position: relative; }
    .box-customer-noti .box-img img {
      height: 40px;
      width: 40px;
      border-radius: 50%; }
    .box-customer-noti .box-img .social {
      position: absolute;
      bottom: -1px;
      right: -3px;
      width: 1.15rem;
      height: 1.15rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-size: 0.75rem;
      z-index: 999; }
      .box-customer-noti .box-img .social.line {
        background-color: #62BA44; }
      .box-customer-noti .box-img .social.facebook {
        background-color: #2E5186; }
      .box-customer-noti .box-img .social.phone {
        background-color: #ED6529; }
      .box-customer-noti .box-img .social i::before {
        font-size: 0.75rem;
        color: #ffffff; }
  .box-customer-noti .detail {
    display: flex;
    flex-direction: column;
    max-width: 82%;
    min-width: 82%;
    position: relative; }
    .box-customer-noti .detail label {
      margin-bottom: 0 !important;
      font-size: 0.85rem;
      color: #004EC2;
      font-weight: bold;
      max-width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .box-customer-noti .detail label.msg {
        color: #131414;
        font-weight: bold; }
    @media (max-width: 768px) {
      .box-customer-noti .detail {
        max-width: 78%;
        min-width: 78%; } }

.wrap-customer {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 87vh;
  overflow-y: scroll;
  background-color: #ffffff;
  border-radius: 5px;
  border-bottom: 1px solid #E0E0E0; }

.left-item {
  display: flex;
  flex-direction: column; }
  .left-item .left-item-top {
    display: flex;
    flex-direction: row;
    justify-content: flex-end; }
  .left-item .left-item-bottom {
    justify-content: flex-end; }

.text-time {
  text-align: right;
  font-size: 0.75rem;
  padding-bottom: 0.3rem; }

.right-item {
  display: flex;
  flex-direction: row;
  max-width: 70%;
  min-width: 70%; }
  @media (max-width: 768px) {
    .right-item {
      max-width: 78%;
      min-width: 78%; } }

.noti-alert {
  min-width: 10px;
  max-width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #EB5757;
  display: block;
  position: absolute;
  margin-top: 0.3rem; }

.btn-resolve {
  color: #ffffff;
  background-color: #004EC2;
  border-color: #004EC2; }
  .btn-resolve:hover {
    color: rgba(255, 255, 255, 0.65) !important; }
  .btn-resolve:focus {
    box-shadow: none !important;
    outline: none !important; }
  .btn-resolve.active {
    background-color: #cccccc;
    border-color: #cccccc;
    color: #ffffff; }

.follow-up {
  background-color: #004EC2;
  color: #ffffff;
  font-size: 12px;
  padding: 0 0.25rem;
  border-radius: 0.25rem;
  display: block;
  margin-right: 15px; }
  .follow-up i {
    padding-right: 0.25rem; }

.btn-follow-up {
  background-color: #27AE60;
  border-color: #27AE60;
  color: #ffffff; }
  .btn-follow-up:hover {
    color: rgba(255, 255, 255, 0.65) !important; }
  .btn-follow-up:focus {
    box-shadow: none !important;
    outline: none !important; }
  .btn-follow-up.active {
    background-color: #cccccc;
    border-color: #cccccc;
    color: #ffffff; }

.wrap-customer::-webkit-scrollbar {
  width: .4em; }

.wrap-customer::-webkit-scrollbar,
.wrap-customer::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

.wrap-customer::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); }

.cover-bar {
  position: absolute;
  background: #fff;
  height: 100%;
  top: 0;
  right: 0;
  width: .4em;
  border-radius: 5px !important;
  -webkit-transition: all .5s;
  opacity: 1; }

.wrap-customer:hover .cover-bar {
  opacity: 0;
  -webkit-transition: all .5s; }

.btn-gray {
  background-color: #cccccc;
  border-color: #cccccc;
  color: #131414; }
  .btn-gray:focus {
    box-shadow: none !important; }

.btn-tag {
  border-color: #004EC2;
  background-color: #004EC2;
  color: #fff;
  padding: .25rem .5rem !important;
  font-size: 0.75rem !important;
  margin: 0.25rem; }
  .btn-tag.active {
    background-color: #FDB513;
    border-color: #FDB513;
    color: #131414; }
    .btn-tag.active:hover {
      color: rgba(0, 0, 0, 0.65) !important; }
  .btn-tag:hover {
    color: rgba(255, 255, 255, 0.65) !important; }

.span-tags {
  background-color: #004EC2;
  color: #ffffff !important;
  font-size: 0.75rem !important;
  padding: 0.159rem  0.45rem !important;
  border-radius: 0.25rem !important;
  margin-left: 5px; }

.haeder-search {
  display: flex;
  flex-direction: row;
  width: 100%; }
  .haeder-search .input-group {
    padding: 1rem 1.25rem 0 1.25rem; }
    .haeder-search .input-group .input-group-prepend button {
      position: absolute;
      color: #828282;
      z-index: 99; }
    .haeder-search .input-group .input-group-prepend .btn-close {
      color: #131414 !important;
      right: 1rem; }
    .haeder-search .input-group input {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px; }
    .haeder-search .input-group .form-control {
      border: 1px solid #F6F6F6 !important;
      border-radius: 5px !important; }

.haeder-cus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.25rem; }
  .haeder-cus .haeder {
    font-weight: bold; }
  .haeder-cus .add-cus {
    min-width: -webkit-max-content;
    min-width: -moz-max-content;
    min-width: max-content;
    font-size: 1rem;
    padding: 0;
    color: #004EC2; }
    .haeder-cus .add-cus i {
      padding-right: 0.5rem;
      font-size: 1.25rem; }

.btn-send {
  color: #ffffff !important;
  background-color: #004EC2 !important;
  border-color: #004EC2 !important;
  border-radius: 5px !important; }
  .btn-send i {
    padding-right: 0.5rem; }

.check-social {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 15px 0 15px;
  align-items: center; }
  .check-social .form-group {
    margin-bottom: 0;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content; }
    .check-social .form-group label {
      font-size: 12px; }
      .check-social .form-group label span {
        padding-left: 25px; }
  .check-social [type="radio"]:checked + label {
    color: #004EC2; }
  .check-social .radio-custom-modal:checked + :before {
    font-size: 1.25rem;
    line-height: 1rem; }
  .check-social .radio-custom-modal + .radio-custom-modal-label:before {
    font-size: 1.25rem;
    line-height: 1rem; }

.box-frist {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 86vh; }
  .box-frist img {
    width: 82px; }
  .box-frist .box-text {
    display: flex;
    flex-direction: column;
    padding-left: 30px; }
    .box-frist .box-text label {
      margin-bottom: 10px;
      font-size: 50px;
      color: #E0E0E0;
      font-weight: bold; }

body {
  font-family: "Sarabun", sans-serif !important;
  background-color: #F6F6F6; }

.btn.focus, .btn:focus {
  box-shadow: none !important; }

.div-all {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  height: 91vh;
  align-items: flex-start;
  min-width: 100%;
  justify-content: space-between;
  background-color: #F6F6F6;
  box-sizing: border-box;
  overflow: hidden; }

.div-list-user {
  min-width: 23rem;
  max-width: 23rem;
  height: 87vh;
  border: 1px solid #E0E0E0;
  position: relative;
  margin-right: 15px;
  border-radius: 5px;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out; }
  @media (max-width: 992px) {
    .div-list-user {
      min-width: 20rem;
      max-width: 20rem; } }
  @media (max-width: 768px) {
    .div-list-user {
      margin-right: 15px;
      min-width: 18rem;
      max-width: 18rem; } }

.div-list-chat {
  width: 100%;
  height: 87vh;
  border: 1px solid #EFF0F6;
  position: relative;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #E0E0E0; }

.css-y1c0xs, [data-css-y1c0xs] {
  padding-right: 1rem; }

.css-y1c0xs::-webkit-scrollbar {
  width: .4em; }

.css-y1c0xs::-webkit-scrollbar,
.css-y1c0xs::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px; }

.css-y1c0xs::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2); }

.cover-bar {
  position: absolute;
  background: #fff;
  height: 100%;
  top: 0;
  right: 0;
  width: .4em;
  -webkit-transition: all .5s;
  opacity: 1; }

.css-y1c0xs:hover .cover-bar {
  opacity: 0;
  -webkit-transition: all .5s; }

.haeder-bar {
  width: 100%;
  height: 3.5rem;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem; }
  .haeder-bar .box-logo {
    height: 2.5rem; }
    .haeder-bar .box-logo img {
      height: 100%; }
  .haeder-bar .user {
    display: flex;
    align-items: center; }
    .haeder-bar .user .box-avatar {
      width: 2rem;
      height: 2rem;
      border-radius: 50%; }
      .haeder-bar .user .box-avatar img {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        object-fit: cover;
        object-position: center; }
    .haeder-bar .user .btn-logout {
      color: #131414;
      border: 0; }
      .haeder-bar .user .btn-logout:focus {
        box-shadow: none !important;
        outline: none !important; }
  .haeder-bar label {
    margin-bottom: 0;
    color: #ffffff;
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: bold; }

body {
  font-family: "Sarabun", sans-serif !important;
  background-color: #F6F6F6; }

.btn.focus, .btn:focus {
  box-shadow: none !important; }

.main-div {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  height: 91vh;
  align-items: flex-start;
  min-width: 100%;
  justify-content: space-between;
  background-color: #F6F6F6;
  box-sizing: border-box;
  overflow: hidden; }

.container-box {
  padding: 2.5rem 5rem;
  height: 100%; }
  @media (max-width: 768.98px) {
    .container-box {
      padding: 2.5rem 0; } }

.gallery-space {
  width: 100%; }

.title-name {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 2.5rem; }

.back-btn {
  font-family: Sarabun;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin: 0 1.125rem;
  color: #004EC2; }

.filter-tab {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  text-align: right;
  margin: 2rem 0 0 0; }
  @media (max-width: 768.98px) {
    .filter-tab {
      text-align: left; } }
  @media (max-width: 991.98px) {
    .filter-tab {
      text-align: left; } }
  .filter-tab .input-group .input-group-prepend .input-group-text {
    background-color: #ffffff;
    border: 1px-solid #E0E0E0;
    border-radius: 2;
    color: #828282;
    border-right: 0; }
    .filter-tab .input-group .input-group-prepend .input-group-text .select-form {
      width: 1000px; }
  .filter-tab .input-group .form-control {
    border-radius: 2;
    border-left: 0; }
  .filter-tab .input-group select.select-form {
    width: 12rem;
    height: 2.375rem;
    background-color: #FFFFFF;
    border: 1px solid #E0E0E0;
    color: #131414;
    border-radius: 5px; }
    @media (max-width: 768.98px) {
      .filter-tab .input-group select.select-form {
        width: 100%; } }

.form-sort {
  float: right; }
  @media (max-width: 768.98px) {
    .form-sort {
      width: 100%;
      float: left; } }
  @media (max-width: 991.98px) {
    .form-sort {
      width: 100%;
      float: left; } }

.sort-create-div {
  display: flex;
  flex-direction: row;
  padding-left: 2rem; }

.checkbox-media-all .select-all {
  position: relative;
  padding: 0 15px;
  text-align: right; }
  .checkbox-media-all .select-all input {
    position: absolute;
    width: 1.125rem;
    height: 1.125rem; }

.checkbox-media-all {
  text-align: end; }
  .checkbox-media-all input {
    width: 1.125rem;
    height: 1.125rem; }

.div-album {
  display: grid;
  grid-gap: 5px;
  justify-items: center;
  margin-bottom: 4rem; }
  .div-album .box-album-relative {
    background-color: #ffffff;
    border: solid 1px #E0E0E0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    height: 11rem;
    width: 11rem;
    margin: 1.125rem 0;
    position: relative; }
    @media (min-width: 1600px) {
      .div-album .box-album-relative {
        height: 14rem;
        width: 14rem; } }
    @media (max-width: 575.98px) {
      .div-album .box-album-relative {
        height: 16rem;
        width: 16rem; } }
  .div-album .box-album {
    background-color: #ffffff;
    border: solid 1px #E0E0E0;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    height: 11rem;
    width: 11rem;
    padding: 0.75rem 0.75rem 1.75rem 0.75rem;
    top: 0;
    right: 5px;
    margin-top: 5px;
    margin-left: -5px;
    position: absolute; }
    @media (min-width: 1600px) {
      .div-album .box-album {
        height: 14rem;
        width: 14rem; } }
    @media (max-width: 575.98px) {
      .div-album .box-album {
        height: 16rem;
        width: 16rem; } }
    .div-album .box-album .img-album {
      object-fit: cover;
      height: 85%;
      width: 100%; }
    .div-album .box-album .row {
      padding: 0.5rem; }
      .div-album .box-album .row .name-album {
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis; }
      .div-album .box-album .row i {
        color: #E0E0E0;
        text-align: center;
        align-self: center; }
  .div-album .box-media-padding {
    padding-bottom: 2rem !important; }
  .div-album .box-media {
    border: solid 1px #E0E0E0;
    height: 12rem;
    margin: 1.125rem 1rem 1.5rem 1rem;
    padding: 0 0 1rem 0;
    position: relative; }
    @media (min-width: 1600px) {
      .div-album .box-media {
        height: 14rem;
        width: 14rem; } }
    @media (max-width: 575.98px) {
      .div-album .box-media {
        height: 16rem;
        width: 16rem; } }
    .div-album .box-media .checkbox-media {
      top: 0.1rem;
      right: 0.5rem;
      position: absolute; }
      .div-album .box-media .checkbox-media input {
        width: 1.125rem;
        height: 1.125rem; }
    .div-album .box-media .img-media {
      object-fit: cover;
      height: 85%;
      width: 100%; }
    .div-album .box-media .row {
      padding: 0.5rem 1rem 0 1rem; }
      .div-album .box-media .row .name-media {
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis; }
      .div-album .box-media .row .sub-name {
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis; }
      .div-album .box-media .row i {
        color: #E0E0E0;
        text-align: center;
        align-self: center; }

.btn-create-media {
  float: right;
  padding: 0 1rem 3rem 0; }
  .btn-create-media .btn-move-media-disable {
    margin: 0 0.5rem;
    padding: 0.5rem 1.5rem;
    color: #FFFFFF;
    background-color: #E0E0E0;
    border-color: #E0E0E0;
    border: solid 1px #E0E0E0;
    border-radius: 5px; }
    .btn-create-media .btn-move-media-disable .movefile {
      width: 24px;
      height: 19.95px; }
  .btn-create-media .btn-move-media-enable {
    margin: 0 0.5rem;
    padding: 0.5rem 1.5rem;
    color: #FFFFFF;
    background-color: #004EC2;
    border-color: #004EC2;
    border: solid 1px #004EC2;
    border-radius: 5px; }
    .btn-create-media .btn-move-media-enable .movefile {
      width: 24px;
      height: 19.95px; }
  .btn-create-media .btn-delete-media-disable {
    margin: 0 1rem;
    padding: 0.5rem 1.5rem;
    color: #FFFFFF;
    background-color: #E0E0E0;
    border-color: #E0E0E0;
    border: solid 1px #E0E0E0;
    border-radius: 5px; }
    .btn-create-media .btn-delete-media-disable i {
      text-align: center;
      font-size: 1.18rem; }
  .btn-create-media .btn-delete-media-enable {
    margin: 0 1rem;
    padding: 0.5rem 1.5rem;
    color: #FFFFFF;
    background-color: #E94D4D;
    border-color: #E0E0E0;
    border: solid 1px #E0E0E0;
    border-radius: 5px; }
    .btn-create-media .btn-delete-media-enable i {
      text-align: center;
      font-size: 1.18rem; }

.cur-point {
  cursor: pointer; }

@media (maxs-width: 575.98px) {
  .div-album {
    grid-template-columns: repeat(1, 1fr); } }

@media (max-width: 768.98px) and (min-width: 576px) {
  .div-album {
    grid-template-columns: repeat(2, 1fr); } }

@media (max-width: 991.98px) and (min-width: 768px) {
  .div-album {
    grid-template-columns: repeat(3, 1fr); } }

@media (max-width: 1199.98px) and (min-width: 992px) {
  .div-album {
    grid-template-columns: repeat(4, 1fr); } }

@media (min-width: 1200px) {
  .div-album {
    grid-template-columns: repeat(5, 1fr); } }

@media (min-width: 1300px) {
  .div-album {
    grid-template-columns: repeat(6, 1fr); } }

