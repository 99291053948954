@import './variables';

.div-chat {
    display: flex;
    flex-direction: column;
    height: 86vh;
    justify-content: space-between;
}
.wrap-div-chat{
    height: 86vh;
    display: block;
}

.div-list-data {
    height: 69vh;
    // overflow-y: scroll;
    // padding: 1rem;
    position: relative;
    
}
.announce{
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 15px;
    .text-announce{
        color: $primaryColor;
        background-color: $secondaryColor;
        width: 100%;
        padding: 0.5rem;
        border-radius: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        .haeder{
            font-weight: bold;
            padding-right: 0.5rem;
        }
    }
}

.chat-box{
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 1rem;
    background-color: #ffffff;
    border-radius:0 0 6px 6px;
    z-index: 999;
    .input-group{
        flex-direction: column;
            .form-control{
                border-radius: 10px;
                border: 1px solid $secondaryColor;
                background-color: $Background;
                margin-right: 1rem !important;
                font-size: 14px;
                font-weight: bold;
                width: 100%;
            }
    }
    
}
.btn-attach{
    background-color: #ffffff!important;
    color:$primaryColor !important;
    border-color:#ffffff!important;
    font-size:1.75rem!important;
    line-height: 1rem;
    padding-top:0!important;
    padding-bottom:0!important;
    position: relative;
    width: max-content;
    .imgcloud{
        margin-bottom: 5px;
        width: 36px;
        height: 25px;
    }
}
.attach-img{
    position: relative;
    display: flex;
    align-items: center;
    background-color: red;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .375rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    margin-right: 0.5rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    .img-upload{
        height: 30px;
        width: 30px;
        background-color: red;
        img{
            height: 30px;
            width: 30px;
            object-fit: cover;
            object-position: center;
        }
    }
    .box-file-name{
        position: relative;
    }
    label{
        padding-left: 0.5rem;
        margin-bottom: 0;
        font-size: 0.85rem;
        color: $Black;
        max-width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis
    }
    .btn-close{
        position: absolute;
        top: -10px;
        right: -8px;
        color: $noti;
        padding: .375rem .5rem;
        &:focus{
            box-shadow: none !important;
            outline: none !important;
        }
    }
}

 input[type=file] {
   outline: 0;
   opacity: 0;
   cursor: pointer;
  //  pointer-events: none;
   user-select: none;
   z-index: 3;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
    height: 100%;
    border-radius: 50%;
 }

.div-msg {
    display: flex;
    border: 1px solid gray;
    width: 90%;
    height: 700px;
    margin: 0.5rem;
}

.div-msg-customer {
    // width: max-content;
    max-width: 45%;
    display: flex;
    align-items: flex-start;
    margin: 1rem 0;
    justify-content:flex-start;
    @media (max-width:768px) {
        max-width: 80%;
       }
}

.div-msg-call-center {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
    span{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-right: 0.5rem;
    font-size: 0.65rem;
    color: gray;
    }
    img{
        width: 100%;
        cursor: pointer;
    }
}

.box-msg-customer {
    // width: max-content;
    // width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    // margin: 1rem;
    .w-sticker{
        width:8rem;
        border-color: #ffffff;
        background-color: #ffffff
    }
    img{
        width: 100%;
        cursor: pointer;
    }
    label{
        border: 1px solid $secondaryColor;
        background-color: $secondaryColor;
        border-radius: 10px;
        padding: .375rem .375rem;
        margin-bottom: 0;
        word-break: break-word;
    }
    div{
        border: 1px solid $secondaryColor;
        background-color: $secondaryColor;
        border-radius: 10px;
        padding: .375rem .375rem;
        margin-bottom: 0;
        max-width: 15rem;
        min-width: max-content;
        display: inline-block;
        word-break: break-word;
    }
    span{
        padding-left: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 0.65rem;
        color: gray;
        min-width:86px;
    }
    img{
        border: 1px solid $secondaryColor;
        background-color: $secondaryColor;
        border-radius: 10px;
        padding: .375rem .75rem;
        margin-bottom: 0;
        width: 100%;
    }
}

.box-img-customer {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin-right: 10px;
    img{
        height: 40px;
        width: 40px;
        border-radius: 20px;
    }
}

.box-img-call-center {
    border: 1px solid red;
    height: 40px;
    width: 40px;
    border-radius: 10px;
}
.wrap-time{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-width:86px;
    padding-bottom: 0.5rem;
}
.box-msg-call-center {
    border: 1px solid $primaryColor;
    background-color: $primaryColor;
    color: #ffffff;
    // width: max-content;
    max-width: 45%;
    border-radius:10px;
    padding: .375rem .375rem;
    word-break: break-word;
  
    @media (max-width:768px) {
        max-width: 80%;
       }
       img{
           width: 100%;
           padding: 0.25rem;
           border-radius: 10px;
       }
}

.wd-he-toscroll {
    height: 100%;
    width: 100%;    
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem;
    padding-bottom: 165px;
}
.btn-close-note{
    position: absolute;
    right: 0rem;
    top: 0rem;
    z-index: 1051;
    i{
        font-size: 1.25rem;
        color: #ffffff;
    }
    &:focus{
        outline: none!important;
        box-shadow: none!important;
    }
    &:hover{
        color: rgba(255, 255, 255, 0.75) !important;
    }
}
.modal-footer-note{
    border-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: .3rem;
    border-bottom-left-radius: .3rem;
}
.pd-t-2{
    padding-top: 2rem;
}
.bg-modal-header{
    background-color: $primaryColor;
    color: #ffffffff !important;
}
.modal-content{
    border: 0!important;
}

.container-map{
    display: block;
    width: 100%;
    background-color: $White;
    // margin-top: 10px;
    padding:15px 0;
}
.div-map {
    width: 100%;
    height: 200px;
    position: relative;
    display: block;
}
.line-haeder{
    border-bottom: 1px solid $Lightgrey;
    margin: 0 1rem;
}
.customer-haeder{
    padding: 1rem;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    
    @media (max-width:768px) {
        flex-direction: column;
        align-items: flex-start;

    }
    .haeder-right{
        
        .btn-search{
            color: $primaryColor;
            margin-right: 0.5rem;
            font-size: 1.5rem;

        }
    }
  
    .profile{
        display: flex;
        flex-direction: row;
        width: max-content;
        align-items: center;
        @media (max-width:768px) {
         padding-bottom: 10px;
        }
        .box-img{
            height: 50px;
            width: 50px;
            border-radius: 10px;
            margin-right: 10px;
            img{
                height: 50px;
                width: 50px;
                border-radius: 10px;
            }
        }
        label{
            margin-bottom: 0;
            color: $Black;
            font-weight: bold;
            padding-left: 0.35rem;
        }
    }
    .status-customer{
        display: flex;
        flex-direction: row;
        align-items: center;
        label{
            margin-bottom: 0;
            padding-left: 10px;
            &.status{
                color: $primaryColor;
            }
        }
    }
}
.status-box{
    display: flex;
    flex-direction: column;
    // span{
    //     font-size: 1rem;
    //     color: $primaryColor;
    //     padding: .375rem 0;
    // }
    .input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
        border-top-right-radius: .25rem!important;
        border-bottom-right-radius: .25rem!important;
    }
    .social{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 0.5rem;
        .line{
            background-color: $Line;
            color: #ffffff;
            width: 1.15rem;
            height: 1.15rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 0.75rem;
        }
        .facebook{
            background-color: $Facebook;
            color: #ffffff;
            width: 1.15rem;
            height: 1.15rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 0.75rem;
        }
        .phone{
            background-color: $Tagorange;
            color: #ffffff;
            width: 1.15rem;
            height: 1.15rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            font-size: 0.75rem;
        }
    }
}
.btn-edit{
    border-color: $primaryColor!important;
    background-color: #ffffff!important;
    color: $primaryColor!important;
    font-size: 0.75rem!important;
    padding: 0rem 0.45rem!important;
    &.edit{
        border-color: #ffffff!important;
    }
    border-radius: .25rem!important;
    &:focus{
        box-shadow: none !important;
    }
}

.div-btn-pick-color-modal-tag {
    padding-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box-select{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        span{
            font-size: 1rem;
            color: $Black;
        }
    }
    .box-button{
        display: flex;
        justify-content: flex-start;
        align-items: center;  
    }
}

.btn-color-picker {
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border:3px solid #ffffff;
    border-radius: 50%;
    padding:0;
    &:focus{
        border: 3px solid $Grey; 
        box-shadow: none!important;
        outline: none!important;
    }
}
.btn-tags{
    color: #ffffff !important;
    border: 3px solid transparent; 
    border-color: #ffffff;
    margin-right: 0.25rem;
    border-radius: 10px !important;
    padding: 0.2rem 0.5rem!important;
    &:focus{
        border: 3px solid $Grey; 
        box-shadow: none!important;
        outline: none!important;
    }
}
.wrap-add-tag{
   padding:1rem 0;
   .input-group{
       position: relative;
    .input-group-prepend{
        position: absolute;
        right: 1rem;
        top: 1.4rem;
        z-index: 99;
        label{
            margin-bottom: 0;
            color: $Darkgrey;
        }
    }
   }
   input{
       margin-top: 1rem;
       padding-right: 4.5rem;
   }
}
.ling-tag{
    border-bottom: 1px solid $Lightgrey;
}
.wrap-select-tags{
   
}
.wrap-btn-file-chat{
 display: block;
 padding-left: 50px;
 padding-bottom: 1rem;
}
.btn-download{
    background-color: #ffffff;
    border-color: #ffffff;
    color: $primaryColor;
    border-right-color: $Black;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem;
    &.border-none{
        border-right-color: transparent;
    }
}

.btn-upload{
    background-color: #ffffff;
    border-color: #ffffff;
    color: $primaryColor;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem;
}

.btn-img-upload{
    &:disabled{
        color: #62BA44 !important;
        opacity: 1 !important;
    }
}

.copy-clipboard{
    min-width: max-content;
}
