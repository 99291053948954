#login-page {
  .wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fafafa;
  }
  .login-form {
    width: 956px;
    height: 438px;
    margin: 0 auto;
    border: 1px solid #ddd;
    padding: 2rem;
    background: #ffffff;
  }
  .form-input {
    background: #fafafa;
    border: 1px solid #eeeeee;
    padding: 12px;
    width: 100%;
  }
  .form-group {
    margin-bottom: 1rem;
  }
  .form-button {
    background: #69d2e7;
    border: 1px solid #ddd;
    color: #ffffff;
    padding: 10px;
    width: 100%;
    text-transform: uppercase;
  }
  .form-button:hover {
    background: #69c8e7;
  }
  .form-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  .form-footer {
    text-align: center;
  }
}
