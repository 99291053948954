#extra-data {
    // background-color: white;
    margin: 0 auto;
    padding: 0 70px 0px 0px;
    background-color: #fff;
    padding: 2em 5em;

    p .text-update{
        color: #ACACAC
    }


    .full-width {
        width: 100%;
    }

    .search-component {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
    margin-bottom: 3px;
    }

    .sort {
        width: 30%;
        margin: 30px 0px;
    }

    .sort-text {
        flex: none;
        margin: 0px 10px;
    }

    .search-item {
        flex-direction: row;
    }

    .search-select {
        margin: 0px 10px;
    }

    .icon-check {
        width: 20px;
        height: 20px;
        margin: 0px 5px;
    }

    .btn-download {
        background-color: #004EC2;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        width: 200px;
        height: 50px;
        color: #fff;
    }
    .btn-download:disabled {
        background-color: #e0e0e0;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        width: 200px;
        height: 50px;
        color: #fff;
    }

    .shop {
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        position: relative;
        height: 60vh;
        // background-color: lightblue;
    }

    .img-icon {
        width: 70px;
        border-radius: 50%;
    }

    .text-update {
        color: #ACACAC;
        font-size: 9px
      }

      .flex-response {
          flex-direction: row;
      }

      @media only screen and (max-width: 700px) {
       .flex-response {
           flex-direction: column;
       }

       .sort {
           width: 100%;
           margin: auto;
       }
      }
    
}
