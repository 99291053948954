@import './variables';

.main-div {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    height: 91vh;
    align-items: flex-start;
    min-width: 100%;
    justify-content: space-between;
    background-color: $Background;
    box-sizing: border-box;
    overflow: hidden;
}

.container-box {
    padding: 2.5rem 5rem;
    height: 100%;

    @media (max-width: 768.98px) {
        padding: 2.5rem 0;
    }
}

.gallery-space {
    width: 100%;
}

.title-name {
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 2.5rem;
}

.back-btn {
    font-family: Sarabun;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin: 0 1.125rem;
    color: #004EC2;
}

.filter-tab {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    text-align: right;
    margin: 2rem 0 0 0;

    @media (max-width: 768.98px) {
        text-align: left;
    }

    @media (max-width: 991.98px) {
        text-align: left;

    }

    .input-group {
        .input-group-prepend {
            .input-group-text {
                background-color: #ffffff;
                border: 1px - solid #E0E0E0;
                border-radius: 2;
                color: #828282;
                border-right: 0;

                .select-form {
                    width: 1000px;
                }
            }
        }

        .form-control {
            border-radius: 2;
            border-left: 0;
        }

        select.select-form {
            width: 12rem;
            height: 2.375rem;
            background-color: #FFFFFF;
            border: 1px solid #E0E0E0;
            color: #131414;
            border-radius: 5px;

            @media (max-width: 768.98px) {
                width: 100%;
            }
        }
    }
}

.form-sort {
    float: right;

    @media (max-width: 768.98px) {
        width: 100%;
        float: left;
    }

    @media (max-width: 991.98px) {
        width: 100%;
        float: left;

    }

}

.sort-create-div {
    display: flex;
    flex-direction: row;
    padding-left: 2rem;
}

.checkbox-media-all {
    .select-all {
        position: relative;
        padding: 0 15px;
        text-align: right;

        input {
            position: absolute;
            width: 1.125rem;
            height: 1.125rem;
        }
    }
}

.checkbox-media-all {
    text-align: end;

    input {
        width: 1.125rem;
        height: 1.125rem;
    }
}

.div-album {
    display: grid;
    grid-gap: 5px;
    justify-items: center;
    margin-bottom: 4rem;

    .box-album-relative {
        background-color: #ffffff;
        border: solid 1px #E0E0E0;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        height: 11rem;
        width: 11rem;
        margin: 1.125rem 0;
        position: relative;

        @media (min-width: 1600px) {
            height: 14rem;
            width: 14rem;
        }

        @media (max-width: 575.98px) {
            height: 16rem;
            width: 16rem;
        }
    }

    .box-album {
        background-color: #ffffff;
        border: solid 1px #E0E0E0;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        height: 11rem;
        width: 11rem;
        padding: 0.75rem 0.75rem 1.75rem 0.75rem;
        top: 0;
        right: 5px;
        margin-top: 5px;
        margin-left: -5px;
        position: absolute;

        @media (min-width: 1600px) {
            height: 14rem;
            width: 14rem;
        }

        @media (max-width: 575.98px) {
            height: 16rem;
            width: 16rem;
        }

        .img-album {
            object-fit: cover;
            height: 85%;
            width: 100%;
        }

        .row {
            padding: 0.5rem;

            .name-album {
                white-space: nowrap;
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            i {
                color: #E0E0E0;
                text-align: center;
                align-self: center;
            }
        }
    }

    .box-media-padding {
        padding-bottom: 2rem !important;
    }

    .box-media {
        border: solid 1px #E0E0E0;
        height: 12rem;
        margin: 1.125rem 1rem 1.5rem 1rem;
        padding: 0 0 1rem 0;
        position: relative;

        @media (min-width: 1600px) {
            height: 14rem;
            width: 14rem;
        }

        @media (max-width: 575.98px) {
            height: 16rem;
            width: 16rem;
        }

        .checkbox-media {
            top: 0.1rem;
            right: 0.5rem;
            position: absolute;

            input {
                width: 1.125rem;
                height: 1.125rem;
            }
        }

        .img-media {
            object-fit: cover;
            height: 85%;
            width: 100%;
        }

        .row {
            padding: 0.5rem 1rem 0 1rem;

            .name-media {
                white-space: nowrap;
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .sub-name {
                white-space: nowrap;
                width: 100px;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            i {
                color: #E0E0E0;
                text-align: center;
                align-self: center;
            }
        }
    }
}

.btn-create-media {
    float: right;
    padding: 0 1rem 3rem 0;

    .btn-move-media-disable {
        margin: 0 0.5rem;
        padding: 0.5rem 1.5rem;
        color: #FFFFFF;
        background-color: #E0E0E0;
        border-color: #E0E0E0;
        border: solid 1px #E0E0E0;
        border-radius: 5px;

        .movefile {
            width: 24px;
            height: 19.95px;
        }
    }

    .btn-move-media-enable {
        margin: 0 0.5rem;
        padding: 0.5rem 1.5rem;
        color: #FFFFFF;
        background-color: #004EC2;
        border-color: #004EC2;
        border: solid 1px #004EC2;
        border-radius: 5px;

        .movefile {
            width: 24px;
            height: 19.95px;
        }
    }

    .btn-delete-media-disable {
        margin: 0 1rem;
        padding: 0.5rem 1.5rem;
        color: #FFFFFF;
        background-color: #E0E0E0;
        border-color: #E0E0E0;
        border: solid 1px #E0E0E0;
        border-radius: 5px;

        i {
            text-align: center;
            font-size: 1.18rem;
        }
    }

    .btn-delete-media-enable {
        margin: 0 1rem;
        padding: 0.5rem 1.5rem;
        color: #FFFFFF;
        background-color: #E94D4D;
        border-color: #E0E0E0;
        border: solid 1px #E0E0E0;
        border-radius: 5px;

        i {
            text-align: center;
            font-size: 1.18rem;
        }
    }
}

.cur-point {
    cursor: pointer;
}

@media (maxs-width: 575.98px) {
    .div-album {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 768.98px) and (min-width: 576px) {
    .div-album {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 991.98px) and (min-width: 768px) {
    .div-album {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media (max-width: 1199.98px) and (min-width: 992px) {
    .div-album {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 1200px) {
    .div-album {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (min-width: 1300px) {
    .div-album {
        grid-template-columns: repeat(6, 1fr);
    }
}